import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Emulator from './emulator'
import Calulator from './calulator'
import Movement from './movement'
import MovementCollective from './movementcollective'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Emulator />}>
        </Route>
        <Route path="/calculator" element={<Calulator />}>
        </Route>
        <Route path="/movement" element={<Movement />}>
        </Route>
        <Route path="/movement-collective" element={<MovementCollective />}>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
