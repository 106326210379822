const movementL = {
    eating: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.13, "max": 3.14 },
            "common": { "min": -3.09, "max": 2.36 },
            "avg": { "min": -2.35, "max": 2.21 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.11, "max": 2.83 },
            "common": { "min": -2.54, "max": 2.05 },
            "avg": { "min": -2.31, "max": -0.54 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -3.13, "max": 3.14 },
            "common": { "min": -3.11, "max": 2.97 },
            "avg": { "min": -2.4, "max": 2.39 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -0.8647, "max": 1.3897 },
            "common": { "min": -0.8647, "max": 0.7715 },
            "avg": { "min": 0.34, "max": 0.7 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -1.4484, "max": 1.1971 },
            "common": { "min": -0.861, "max": 0.8382 },
            "avg": { "min": -0.86, "max": 0.69 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -1.4143, "max": 1.1036 },
            "common": { "min": -0.7145, "max": 0.6268 },
            "avg": { "min": -0.8, "max": 0.66 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 2, "max": 322 },
            "common": { "min": 24, "max": 82 },
            "avg": { "min": 43.57, "max": 67.57 }
        }
    },
    walking: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.13, "max": 3.15 },
            "common": { "min": -2.53, "max": 3.01 },
            "avg": { "min": -2.06, "max": 2.14 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.13, "max": 3.14 },
            "common": { "min": -0.09, "max": 2.86 },
            "avg": { "min": 0.11, "max": 1.68 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -2.99, "max": 2.76 },
            "common": { "min": -1.73, "max": 1.88 },
            "avg": { "min": -1.34, "max": 1.47 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -1.362, "max": 0.8934 },
            "common": { "min": -0.8593, "max": 0.2237 },
            "avg": { "min": -0.49, "max": -0.06 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -1.3056, "max": 1.4048 },
            "common": { "min": -0.956, "max": 1.014 },
            "avg": { "min": -0.95, "max": 0.92 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -1.4489, "max": 1.3436 },
            "common": { "min": -1.041, "max": 0.8013 },
            "avg": { "min": -0.9, "max": 0.81 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 1, "max": 363 },
            "common": { "min": 27, "max": 106 },
            "avg": { "min": 49.13, "max": 90.77 }
        }
    },
    running: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.12, "max": 3.14 },
            "common": { "min": -2.43, "max": 2.48 },
            "avg": { "min": -1.67, "max": 1.69 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.13, "max": 3.12 },
            "common": { "min": -0.07, "max": 2.45 },
            "avg": { "min": 0.18, "max": 1.54 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -3.14, "max": 2.97 },
            "common": { "min": -2.46, "max": 1.72 },
            "avg": { "min": -1.34, "max": 1.32 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -1.7746, "max": 2 },
            "common": { "min": -0.487, "max": 0.241 },
            "avg": { "min": -0.45, "max": -0.2 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -1.9941, "max": 1.91 },
            "common": { "min": -1.3742, "max": 1.4856 },
            "avg": { "min": -1.04, "max": 0.97 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -1.6831, "max": 1.9932 },
            "common": { "min": -1.351, "max": 0.5828 },
            "avg": { "min": -0.97, "max": 0.66 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 5, "max": 433 },
            "common": { "min": 40, "max": 225 },
            "avg": { "min": 100.19, "max": 180.85 }
        }
    },
    sitting: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.14, "max": 3.11 },
            "common": { "min": -2.04, "max": 2.55 },
            "avg": { "min": -2.02, "max": 1.89 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.07, "max": 3.09 },
            "common": { "min": -3.07, "max": 2.19 },
            "avg": { "min": 0.66, "max": 2.1 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -1.49, "max": 1.68 },
            "common": { "min": -0.84, "max": 1.68 },
            "avg": { "min": -0.52, "max": 0.6 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -1.527, "max": 0.0582 },
            "common": { "min": -1.0092, "max": 0.0582 },
            "avg": { "min": -0.9, "max": -0.66 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -0.8542, "max": 0.9031 },
            "common": { "min": -0.6567, "max": 0.7474 },
            "avg": { "min": -0.53, "max": 0.47 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -0.8835, "max": 0.9554 },
            "common": { "min": -0.6391, "max": 0.8384 },
            "avg": { "min": -0.55, "max": 0.46 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 0, "max": 172 },
            "common": { "min": 4, "max": 14 },
            "avg": { "min": 5.96, "max": 12.02 }
        }
    },
    standingNormal: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.12, "max": 3.14 },
            "common": { "min": -2.92, "max": 3.07 },
            "avg": { "min": -2.13, "max": 1.99 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.13, "max": 3.14 },
            "common": { "min": -3.07, "max": 3.09 },
            "avg": { "min": -0.37, "max": 2.69 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -2.79, "max": 2.3 },
            "common": { "min": -1.42, "max": 1.68 },
            "avg": { "min": -1.24, "max": 1.32 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -0.9526, "max": 0.7855 },
            "common": { "min": -0.8144, "max": 0.0582 },
            "avg": { "min": -0.55, "max": -0.17 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -1.0295, "max": 1.0162 },
            "common": { "min": -0.8432, "max": 0.9271 },
            "avg": { "min": -0.89, "max": 0.92 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -0.9733, "max": 1.0369 },
            "common": { "min": -0.8784, "max": 1.0369 },
            "avg": { "min": -0.76, "max": 0.88 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 0, "max": 253 },
            "common": { "min": 3, "max": 14 },
            "avg": { "min": 8.06, "max": 24.09 }
        }
    },
    layingSideSleeper: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.11, "max": 3.09 },
            "common": { "min": -2.94, "max": 3.06 },
            "avg": { "min": -2.4, "max": 2.17 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -2.46, "max": 3.01 },
            "common": { "min": 0.71, "max": 2.19 },
            "avg": { "min": 0.8, "max": 2.29 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -1.2, "max": 2.13 },
            "common": { "min": -0.87, "max": 0.77 },
            "avg": { "min": -0.75, "max": 0.76 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -1.1508, "max": 0.4854 },
            "common": { "min": -0.9045, "max": -0.1748 },
            "avg": { "min": -0.83, "max": -0.66 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -0.9567, "max": 1.336 },
            "common": { "min": -0.9567, "max": 0.8658 },
            "avg": { "min": -0.68, "max": 0.69 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -0.9841, "max": 1.0582 },
            "common": { "min": -0.7492, "max": 0.5892 },
            "avg": { "min": -0.63, "max": 0.63 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 0, "max": 151 },
            "common": { "min": 2, "max": 14 },
            "avg": { "min": 8.41, "max": 20.13 }
        }
    },
    layingSuperMan: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.07, "max": 3.14 },
            "common": { "min": -2.41, "max": 3.07 },
            "avg": { "min": -2.18, "max": 2.62 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.13, "max": 3.14 },
            "common": { "min": -3.1, "max": 2.39 },
            "avg": { "min": -3, "max": 0.88 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -2.88, "max": 2.82 },
            "common": { "min": -2.81, "max": 2.69 },
            "avg": { "min": -1.69, "max": 1.81 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -0.9812, "max": 0.3687 },
            "common": { "min": -0.0905, "max": 0.2862 },
            "avg": { "min": -0.31, "max": 0.13 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -1.0153, "max": 1.0149 },
            "common": { "min": -0.9816, "max": 0.9908 },
            "avg": { "min": -0.94, "max": 0.99 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -1.0122, "max": 1.0086 },
            "common": { "min": -0.9973, "max": 0.9981 },
            "avg": { "min": -0.96, "max": 0.97 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 1, "max": 85 },
            "common": { "min": 2, "max": 7 },
            "avg": { "min": 4.69, "max": 7.9 }
        }
    },
    layingBellyUp: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.13, "max": 3.14 },
            "common": { "min": -3.1, "max": 3.03 },
            "avg": { "min": -2.34, "max": 3.01 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.09, "max": 3.12 },
            "common": { "min": -3.03, "max": 3.12 },
            "avg": { "min": -3.04, "max": 3.1 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -3.11, "max": 3.1 },
            "common": { "min": -2.96, "max": 2.08 },
            "avg": { "min": -1.99, "max": 2.45 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -0.2932, "max": 0.3187 },
            "common": { "min": -0.0969, "max": 0.3047 },
            "avg": { "min": -0.14, "max": 0.24 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -0.9812, "max": 0.9991 },
            "common": { "min": -0.9538, "max": 0.9817 },
            "avg": { "min": -0.96, "max": 0.99 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -0.7897, "max": 1.0074 },
            "common": { "min": -0.2014, "max": 0.9883 },
            "avg": { "min": -0.19, "max": 0.99 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 0, "max": 43 },
            "common": { "min": 1, "max": 5 },
            "avg": { "min": 3.13, "max": 11.41 }
        }
    },
    layingDoughnutLeft: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.13, "max": 2.96 },
            "common": { "min": -2.91, "max": 2.94 },
            "avg": { "min": -2.79, "max": 2.69 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.13, "max": 3.15 },
            "common": { "min": -3.12, "max": 2.99 },
            "avg": { "min": -3.08, "max": 2.85 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -3.12, "max": 3.09 },
            "common": { "min": -2.89, "max": 2.33 },
            "avg": { "min": -2.87, "max": 1.99 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -0.0954, "max": 0.3187 },
            "common": { "min": -0.0869, "max": 0.3187 },
            "avg": { "min": -0.04, "max": 0.17 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -1, "max": 0.9993 },
            "common": { "min": -0.9987, "max": 0.9915 },
            "avg": { "min": -0.96, "max": 0.96 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -1.0144, "max": 1.0132 },
            "common": { "min": -0.9768, "max": 1.0096 },
            "avg": { "min": -0.98, "max": 0.97 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 2, "max": 38 },
            "common": { "min": 4, "max": 7 },
            "avg": { "min": 4.45, "max": 6.66 }
        }
    },
    layingDoughnutRight: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.02, "max": 2.99 },
            "common": { "min": -3, "max": 2.79 },
            "avg": { "min": -2.58, "max": 2.71 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.13, "max": 3.14 },
            "common": { "min": -3.1, "max": 3.11 },
            "avg": { "min": -3.07, "max": 1.3 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -2.23, "max": 2.4 },
            "common": { "min": -1.9, "max": 2.24 },
            "avg": { "min": -1.79, "max": 2.29 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -0.2949, "max": 0.3724 },
            "common": { "min": -0.1474, "max": 0.3621 },
            "avg": { "min": -0.09, "max": 0.34 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -1.0673, "max": 1.2259 },
            "common": { "min": -0.9609, "max": 0.8438 },
            "avg": { "min": -0.98, "max": 0.78 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -0.9916, "max": 0.9991 },
            "common": { "min": -0.9851, "max": 0.991 },
            "avg": { "min": -0.89, "max": 0.89 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 0, "max": 82 },
            "common": { "min": 2, "max": 7 },
            "avg": { "min": 3.29, "max": 17.4 }
        }
    }
}

export default movementL
