import React, { useEffect, useState, useRef, useCallback } from 'react'
import "./App.css";
import moment from 'moment'


export default function Calulator() {

    const [files, setFiles] = useState([])
    const [chart, setChart] = useState([])
    const [position, setPosition] = useState()
    const [pose, setPose] = useState()
    const [eating, setEating]= useState(0)
    const [walking, setWalking]= useState(0)
    const [running, setRunning]= useState(0)
    const [sitting, setSitting]= useState(0)
    const [standingN, setStandingN]= useState(0)
    const [standingLU, setStandingLU]= useState(0)
    const [layingSS, setLayingSS]= useState(0)
    const [layingSM, setLayingSM]= useState(0)
    const [layingBU, setLayingBU]= useState(0)
    const [layingDL, setLayingDL]= useState(0)
    const [layingDR, setLayingDR]= useState(0)
    const [timePer, setTimePer]= useState(0)
    const [duringTime, setDuringTime]= useState(0)
    const [dataMovement, setDataMovement]= useState([])

    const dataDownload = []

    const handleChange = e => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8")
        fileReader.onload = e => {
            setFiles(e?.target?.result);
        };
    };

    const dataSheet = [
        { variant: 'Loose Fit', rotation: '0' },
        { variant: 'Regular Fit', rotation: '0' },
        { variant: 'Loose Fit', rotation: '45' },
        { variant: 'Regular Fit', rotation: '45' },
        { variant: 'Loose Fit', rotation: '90' },
        { variant: 'Regular Fit', rotation: '90' },
        { variant: 'Loose Fit', rotation: '135' },
        { variant: 'Regular Fit', rotation: '135' },
        { variant: 'Loose Fit', rotation: '180' },
        { variant: 'Regular Fit', rotation: '180' },
        { variant: 'Loose Fit', rotation: '225' },
        { variant: 'Regular Fit', rotation: '225' },
        { variant: 'Loose Fit', rotation: '270' },
        { variant: 'Regular Fit', rotation: '270' },
        { variant: 'Loose Fit', rotation: '315' },
        { variant: 'Regular Fit', rotation: '315' },
    ]

    const dataSheetLoose = [
        { variant: 'Loose Fit', rotation: '0' },
        { variant: 'Loose Fit', rotation: '45' },
        { variant: 'Loose Fit', rotation: '90' },
        { variant: 'Loose Fit', rotation: '135' },
        { variant: 'Loose Fit', rotation: '180' },
        { variant: 'Loose Fit', rotation: '225' },
        { variant: 'Loose Fit', rotation: '270' },
        { variant: 'Loose Fit', rotation: '315' },
    ]

    const dataSheetRegular = [
        { variant: 'Regular Fit', rotation: '0' },
        { variant: 'Regular Fit', rotation: '45' },
        { variant: 'Regular Fit', rotation: '90' },
        { variant: 'Regular Fit', rotation: '135' },
        { variant: 'Regular Fit', rotation: '180' },
        { variant: 'Regular Fit', rotation: '225' },
        { variant: 'Regular Fit', rotation: '270' },
        { variant: 'Regular Fit', rotation: '315' },
    ]

    const dataSizes = [
        { size: 'M' },
        { size: 'L' },
        { size: 'XL' },
    ]

    const findMax = (data) => {
        let max = Number(data[0]);
        for (let i = 0; i < data.length; i++) {
            if (Number(max) < Number(data[i])) {
                max = Number(data[i])
            }
        }
        // console.log('max: ', max)
        return max
    }

    const findMin = (data) => {
        let min = Number(data[0]);
        for (let i = 0; i < data.length; i++) {
            if (Number(min) > Number(data[i])) {
                min = Number(data[i])
            }
        }
        // console.log('min: ', min)
        return min
    }

    const calculatorAvg = (data) => {
        if (data.length > 0) {
            let total = 0;
            for (let i = 0; i < data.length; i++) {
                total = Number(total) + Number(data[i])
            }
            let avg = Number(total) / Number(data.length)
            // console.log('avg: ', avg.toFixed(2))
            return avg.toFixed(2)
        }
    }

    const findCommon = (data) => {
        let dataRev = data
        if (dataRev.length > 0) {
            dataRev.sort((a, b) =>
                dataRev.filter(v => v === a).length
                - dataRev.filter(v => v === b).length
            ).pop();
            // console.log('common: ', dataRev[dataRev.length - 1])
            return Number(dataRev[dataRev.length - 1])
        }
    }

    const onClickGet = () => {
        if (files.length > 0) {
            let dataParse = JSON.parse(files.split(","))
            const dataTotal = []
            for (let i = 0; i < dataSheet.length; i++) {
                let item = {}
                pose !== undefined
                    ? (item = dataParse.filter(
                        (val) =>
                            val?.ROTATION === Number(dataSheet[i]?.rotation) &&
                            val?.VARIANT === dataSheet[i]?.variant &&
                            val?.POSITION === position &&
                            val?.POSE === pose
                    ))
                    : (item = dataParse.filter(
                        (val) =>
                            val?.ROTATION === Number(dataSheet[i]?.rotation) &&
                            val?.VARIANT === dataSheet[i]?.variant &&
                            val?.POSITION === position
                    ))
                dataTotal.push(item)
            }
            console.log('dataTotal: ', dataTotal)
            const dataTotalLength = dataTotal.length
            let dataTotalPitch = []
            let dataTotalRoll = []
            let dataTotalYaw = []
            let dataTotalAccX = []
            let dataTotalAccY = []
            let dataTotalAccZ = []
            let dataTotalVel = []
            for (let t = 0; t < dataTotalLength; t++) {
                const length = dataTotal[t].length
                let dataPitch = []
                for (let i = 0; i < length; i++) {
                    let itemPitch = {}
                    itemPitch = dataTotal[t][i]?.PITCH
                    dataPitch.push(itemPitch)
                }
                dataTotalPitch.push(dataPitch)
                let dataRoll = []
                for (let i = 0; i < length; i++) {
                    let itemRoll = {}
                    itemRoll = dataTotal[t][i]?.ROLL
                    dataRoll.push(itemRoll)
                }
                dataTotalRoll.push(dataRoll)
                let dataYaw = []
                for (let i = 0; i < length; i++) {
                    let itemYaw = {}
                    itemYaw = dataTotal[t][i]?.YAW
                    dataYaw.push(itemYaw)
                }
                dataTotalYaw.push(dataYaw)
                let dataX = []
                for (let i = 0; i < length; i++) {
                    let itemX = {}
                    itemX = dataTotal[t][i]?.['ACC X']
                    dataX.push(itemX)
                }
                dataTotalAccX.push(dataX)
                let dataY = []
                for (let i = 0; i < length; i++) {
                    let itemY = {}
                    itemY = dataTotal[t][i]?.['ACC Y']
                    dataY.push(itemY)
                }
                dataTotalAccY.push(dataY)
                let dataZ = []
                for (let i = 0; i < length; i++) {
                    let itemZ = {}
                    itemZ = dataTotal[t][i]?.['ACC Z']
                    dataZ.push(itemZ)
                }
                dataTotalAccZ.push(dataZ)
                let dataV = []
                for (let i = 0; i < length; i++) {
                    let itemV = {}
                    itemV = dataTotal[t][i]?.VELOCITY
                    dataV.push(itemV)
                }
                dataTotalVel.push(dataV)
                let itemSub = {
                    size: dataTotal[t][0]?.SIZE,
                    position: position,
                    pose: pose,
                    rotation: dataTotal[t][0]?.ROTATION,
                    variant: dataTotal[t][0]?.VARIANT,
                    '///': '',
                    Pitch: {
                        avg: calculatorAvg(dataPitch),
                        common: findCommon(dataPitch),
                        min: findMin(dataPitch),
                        max: findMax(dataPitch)
                    },
                    Roll: {
                        avg: calculatorAvg(dataRoll),
                        common: findCommon(dataRoll),
                        min: findMin(dataRoll),
                        max: findMax(dataRoll)
                    },
                    Yaw: {
                        avg: calculatorAvg(dataYaw),
                        common: findCommon(dataYaw),
                        min: findMin(dataYaw),
                        max: findMax(dataYaw)
                    },
                    AccX: {
                        avg: calculatorAvg(dataX),
                        common: findCommon(dataX),
                        min: findMin(dataX),
                        max: findMax(dataX)
                    },
                    AccY: {
                        avg: calculatorAvg(dataY),
                        common: findCommon(dataY),
                        min: findMin(dataY),
                        max: findMax(dataY)
                    },
                    AccZ: {
                        avg: calculatorAvg(dataZ),
                        common: findCommon(dataZ),
                        min: findMin(dataZ),
                        max: findMax(dataZ)
                    },
                    Velocity: {
                        avg: calculatorAvg(dataV),
                        common: findCommon(dataV),
                        min: findMin(dataV),
                        max: findMax(dataV)
                    }
                }
                dataDownload.push(itemSub)
                setChart(dataDownload)
                // console.log('dataDownload: ', dataDownload)
            }
            const dataGenModel = [
                {name: 'Pitch', data: dataTotalPitch},
                {name: 'Roll', data: dataTotalRoll},
                {name: 'Yaw', data: dataTotalYaw},
                {name: 'AccX', data: dataTotalAccX},
                {name: 'AccY', data: dataTotalAccY},
                {name: 'AccZ', data: dataTotalAccZ},
                {name: 'Velocity', data: dataTotalVel}
            ]
            for (let k = 0; k < dataGenModel.length; k++) {
                getModel(dataGenModel[k], dataDownload)
            }
        }
    }

    const onClickGetModel = () => {
        if (files.length > 0) {
            let dataParse = JSON.parse(files.split(","))
            let dataFilter = pose === undefined ? dataParse.filter((val) => val?.name === position)
                : dataParse.filter((val) => val?.name === (position + ' ' + pose))
            console.log('dataFilter: ', dataFilter)
            const dataTotalLength = dataFilter.length
            let dataTotalPitch = []
            let dataTotalRoll = []
            let dataTotalYaw = []
            let dataTotalAccX = []
            let dataTotalAccY = []
            let dataTotalAccZ = []
            let dataTotalVel = []
            let dataPitch = []
            for (let i = 0; i < dataTotalLength; i++) {
                let itemPitch = {}
                itemPitch = dataFilter[i]?.Pitch
                dataPitch.push(itemPitch)
            }
            dataTotalPitch.push(dataPitch)
            let dataRoll = []
            for (let i = 0; i < dataTotalLength; i++) {
                let itemRoll = {}
                itemRoll = dataFilter[i]?.Roll
                dataRoll.push(itemRoll)
            }
            dataTotalRoll.push(dataRoll)
            let dataYaw = []
            for (let i = 0; i < dataTotalLength; i++) {
                let itemYaw = {}
                itemYaw = dataFilter[i]?.Yaw
                dataYaw.push(itemYaw)
            }
            dataTotalYaw.push(dataYaw)
            let dataX = []
            for (let i = 0; i < dataTotalLength; i++) {
                let itemX = {}
                itemX = dataFilter[i]?.accX
                dataX.push(itemX)
            }
            dataTotalAccX.push(dataX)
            let dataY = []
            for (let i = 0; i < dataTotalLength; i++) {
                let itemY = {}
                itemY = dataFilter[i]?.accY
                dataY.push(itemY)
            }
            dataTotalAccY.push(dataY)
            let dataZ = []
            for (let i = 0; i < dataTotalLength; i++) {
                let itemZ = {}
                itemZ = dataFilter[i]?.accZ
                dataZ.push(itemZ)
            }
            dataTotalAccZ.push(dataZ)
            let dataV = []
            for (let i = 0; i < dataTotalLength; i++) {
                let itemV = {}
                itemV = dataFilter[i]?.Motion
                dataV.push(itemV)
            }
            dataTotalVel.push(dataV)
            const dataGenModel = [
                { name: 'Pitch', data: dataTotalPitch },
                { name: 'Roll', data: dataTotalRoll },
                { name: 'Yaw', data: dataTotalYaw },
                { name: 'AccX', data: dataTotalAccX },
                { name: 'AccY', data: dataTotalAccY },
                { name: 'AccZ', data: dataTotalAccZ },
                { name: 'Velocity', data: dataTotalVel }
            ]
            for (let k = 0; k < dataGenModel.length; k++) {
                getModel(dataGenModel[k], dataDownload)
            }
        }
    }

    let dataExport = []

    const getModel = (dataGenModel, dataDownload) => {
        const data = dataGenModel?.data
        // const length = data.length
        // let dataPitch = []
        // for (let t = 0; t < length; t++) {
        //     let itemPitch = {}
        //     itemPitch = data[t]
        //     dataPitch.push(itemPitch)
        // }
        // let dataCommon = []
        // for (let i = 0; i < dataDownload.length; i++) {
        //     let itemPitch = {}
        //     itemPitch = dataDownload[i]?.[dataGenModel?.name]?.common
        //     dataCommon.push(itemPitch)
        // }
        // let dataAvg = []
        // for (let i = 0; i < dataDownload.length; i++) {
        //     let itemPitch = {}
        //     itemPitch = dataDownload[i]?.[dataGenModel?.name]?.avg
        //     dataAvg.push(itemPitch)
        // }
        let range = {
            min: findMin(data[0]),
            max: findMax(data[0])
        }
        let common = findCommon(data[0])
        let avg = calculatorAvg(data[0])
        let itemExport = {
            name: dataGenModel?.name,
            range: range,
            common: common,
            avg: avg
        }
        dataExport.push(itemExport)
        // console.log(`${dataGenModel?.name}`, 'range', range, 'common', common, 'avg', avg)
        console.log(`${dataGenModel?.name}: `,   range)
    }

    const Button = ({ text, className, pin, textColor, onClick }) => {
        return (
            <div onClick={onClick} className={className}>
                <p style={{ fontSize: 16, fontWeight: 700, color: textColor }}>{text}</p>
            </div>
        )
    }

    const onClickExport = () => {
        onClickGet()
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(dataExport)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "data.json";

        link.click();
    }

    const onClickGraph = () => {
        onClickGetModel()
        // const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        //     JSON.stringify(dataExport)
        // )}`;
        // const link = document.createElement("a");
        // link.href = jsonString;
        // link.download = "data.json";

        // link.click();
        console.log('dataDownload: ', dataDownload)
    }

    const onClickMovement = () => {
        if (files.length > 0) {
            let dataParse = JSON.parse(files.split(","))
            console.log('dataParse: ', dataParse)
            const timePerRecord = dataParse[0]?.during / dataParse.length
            const convertTime = moment().startOf('day')
                .seconds((dataParse[0]?.during.toFixed(0) / 3600).toFixed(0))
                .format('H:mm:ss')
            setDuringTime(convertTime)
            console.log('convertTime: ', convertTime)
            setTimePer(timePerRecord.toFixed(0))
            setEating(dataParse.filter((val) => val?.name === 'Eating').length)
            setWalking(dataParse.filter((val) => val?.name === 'Walking').length)
            setRunning(dataParse.filter((val) => val?.name === 'Running').length)
            setSitting(dataParse.filter((val) => val?.name === 'Sitting').length)
            setStandingN(dataParse.filter((val) => val?.name === 'Standing Normal').length)
            setStandingLU(dataParse.filter((val) => val?.name === 'Standing Looking Up').length)
            setLayingSS(dataParse.filter((val) => val?.name === 'Laying Side Sleeper').length)
            setLayingSM(dataParse.filter((val) => val?.name === 'Laying Super Man').length)
            setLayingBU(dataParse.filter((val) => val?.name === 'Laying Belly Up').length)
            setLayingDL(dataParse.filter((val) => val?.name === 'Laying Doughnut Left').length)
            setLayingDR(dataParse.filter((val) => val?.name === 'Laying Doughnut Right').length)

            const deleteDuring = dataParse.shift()
            // const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            //     JSON.stringify(dataParse)
            // )}`;
            // const link = document.createElement("a");
            // link.href = jsonString;
            // link.download = `${moment().format('YYYY-MM-DD_H-mm-ss')}-movement.json`;
            // link.click();
        }
    }

    const renderChart = (data) => {
        return (
            <div>
                {data.map((item, index) => {
                    const widthPitch = ((Number(item?.Pitch?.max).toFixed(1) - Number(item?.Pitch?.min).toFixed(1)) * 100).toFixed(1)
                    const marginLeftPitch = Number(item?.Pitch?.min) > 0
                        ? ((3 + Math.abs(Number(item?.Pitch?.min).toFixed(1))) * 100).toFixed(1) : ((3 - Math.abs(Number(item?.Pitch?.min).toFixed(1))) * 100).toFixed(1)
                    const widthRoll = ((Number(item?.Roll?.max).toFixed(1) - Number(item?.Roll?.min).toFixed(1)) * 100).toFixed(1)
                    const marginLeftRoll = Number(item?.Roll?.min) > 0
                        ? ((3 + Math.abs(Number(item?.Roll?.min).toFixed(1))) * 100).toFixed(1) : ((3 - Math.abs(Number(item?.Roll?.min).toFixed(1))) * 100).toFixed(1)
                    const widthYaw = ((Number(item?.Yaw?.max).toFixed(1) - Number(item?.Yaw?.min).toFixed(1)) * 100).toFixed(1)
                    const marginLeftYaw = Number(item?.Yaw?.min) > 0
                        ? ((3 + Math.abs(Number(item?.Yaw?.min).toFixed(1))) * 100).toFixed(1) : ((3 - Math.abs(Number(item?.Yaw?.min).toFixed(1))) * 100).toFixed(1)
                    const widthX = ((Number(item?.AccX?.max).toFixed(1) - Number(item?.AccX?.min).toFixed(1)) * 100).toFixed(1)
                    const marginLeftX = Number(item?.AccX?.min) > 0
                        ? ((3 + Math.abs(Number(item?.AccX?.min).toFixed(1))) * 100).toFixed(1) : ((3 - Math.abs(Number(item?.AccX?.min).toFixed(1))) * 100).toFixed(1)
                    const widthY = ((Number(item?.AccY?.max).toFixed(1) - Number(item?.AccY?.min).toFixed(1)) * 100).toFixed(1)
                    const marginLeftY = Number(item?.AccY?.min) > 0
                        ? ((3 + Math.abs(Number(item?.AccY?.min).toFixed(1))) * 100).toFixed(1) : ((3 - Math.abs(Number(item?.AccY?.min).toFixed(1))) * 100).toFixed(1)
                    const widthZ = ((Number(item?.AccZ?.max).toFixed(1) - Number(item?.AccZ?.min).toFixed(1)) * 100).toFixed(1)
                    const marginLeftZ = Number(item?.AccZ?.min) > 0
                        ? ((3 + Math.abs(Number(item?.AccZ?.min).toFixed(1))) * 100).toFixed(1) : ((3 - Math.abs(Number(item?.AccZ?.min).toFixed(1))) * 100).toFixed(1)
                    return (
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 30 }}>
                            <div style={{ display: 'flex', width: 300, flexDirection: 'column', alignItems: 'center' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: 400 }}>
                                    <div style={{ fontWeight: 700, width: '20%' }}>TYPE</div>
                                    <div style={{ fontWeight: 700, width: '20%' }}>FROM</div>
                                    <div style={{ fontWeight: 700, width: '20%' }}>TO</div>
                                    <div style={{ fontWeight: 700, width: '20%' }}>COM</div>
                                    <div style={{ fontWeight: 700, width: '20%' }}>AVG</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: 400 }}>
                                    <div style={{ fontWeight: 700, width: '20%', display: 'flex' }}>
                                        <div style={{
                                            backgroundColor: '#ffbe76', height: 16,
                                            width: 16, borderRadius: 8, marginRight: 5
                                        }}></div>
                                        Pitch
                                    </div>
                                    <div style={{ width: '20%' }}>{Number(item?.Pitch?.min).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.Pitch?.max).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.Pitch?.common).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.Pitch?.avg).toFixed(1)}</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: 400 }}>
                                    <div style={{ fontWeight: 700, width: '20%', display: 'flex' }}>
                                        <div style={{
                                            backgroundColor: '#f6e58d', height: 16,
                                            width: 16, borderRadius: 8, marginRight: 5
                                        }}></div>
                                        Roll
                                    </div>
                                    <div style={{ width: '20%' }}>{Number(item?.Roll?.min).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.Roll?.max).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.Roll?.common).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.Roll?.avg).toFixed(1)}</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: 400 }}>
                                    <div style={{ fontWeight: 700, width: '20%', display: 'flex' }}>
                                        <div style={{
                                            backgroundColor: '#badc58', height: 16,
                                            width: 16, borderRadius: 8, marginRight: 5
                                        }}></div>
                                        Yaw
                                    </div>
                                    <div style={{ width: '20%' }}>{Number(item?.Yaw?.min).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.Yaw?.max).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.Yaw?.common).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.Yaw?.avg).toFixed(1)}</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: 400 }}>
                                    <div style={{ fontWeight: 700, width: '20%', display: 'flex' }}>
                                        <div style={{
                                            backgroundColor: '#54a0ff', height: 16,
                                            width: 16, borderRadius: 8, marginRight: 5
                                        }}></div>
                                        AccX
                                    </div>
                                    <div style={{ width: '20%' }}>{Number(item?.AccX?.min).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.AccX?.max).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.AccX?.common).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.AccX?.avg).toFixed(1)}</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: 400 }}>
                                    <div style={{ fontWeight: 700, width: '20%', display: 'flex' }}>
                                        <div style={{
                                            backgroundColor: '#9980FA', height: 16,
                                            width: 16, borderRadius: 8, marginRight: 5
                                        }}></div>
                                        AccY
                                    </div>
                                    <div style={{ width: '20%' }}>{Number(item?.AccY?.min).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.AccY?.max).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.AccY?.common).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.AccY?.avg).toFixed(1)}</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: 400 }}>
                                    <div style={{ fontWeight: 700, width: '20%', display: 'flex' }}>
                                        <div style={{
                                            backgroundColor: '#f8a5c2', height: 16,
                                            width: 16, borderRadius: 8, marginRight: 5
                                        }}></div>
                                        AccZ
                                    </div>
                                    <div style={{ width: '20%' }}>{Number(item?.AccZ?.min).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.AccZ?.max).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.AccZ?.common).toFixed(1)}</div>
                                    <div style={{ width: '20%' }}>{Number(item?.AccZ?.avg).toFixed(1)}</div>
                                </div>
                            </div>
                            <div style={{ width: 100 }}></div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: 600 }} key={index}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>-3</div>
                                    {/* <div>-2.5</div> */}
                                    <div>-2</div>
                                    {/* <div>-1.5</div> */}
                                    <div>-1</div>
                                    <div>0</div>
                                    <div>1</div>
                                    {/* <div>1.5</div> */}
                                    <div>2</div>
                                    {/* <div>2.5</div> */}
                                    <div>3</div>
                                </div>
                                <div style={{
                                    backgroundColor: '#ffbe76', height: 20,
                                    marginLeft: Number(marginLeftPitch),
                                    width: Number(widthPitch)
                                }}></div>
                                <div style={{
                                    backgroundColor: '#f6e58d', height: 20,
                                    marginLeft: Number(marginLeftRoll),
                                    width: Number(widthRoll)
                                }}></div>
                                <div style={{
                                    backgroundColor: '#badc58', height: 20,
                                    marginLeft: Number(marginLeftYaw),
                                    width: Number(widthYaw)
                                }}></div>
                                <div style={{
                                    backgroundColor: '#54a0ff', height: 20,
                                    marginLeft: Number(marginLeftX),
                                    width: Number(widthX)
                                }}></div>
                                <div style={{
                                    backgroundColor: '#9980FA', height: 20,
                                    marginLeft: Number(marginLeftY),
                                    width: Number(widthY)
                                }}></div>
                                <div style={{
                                    backgroundColor: '#f8a5c2', height: 20,
                                    marginLeft: Number(marginLeftZ),
                                    width: Number(widthZ)
                                }}></div>
                                <div style={{ marginTop: 10, display: 'flex', justifyContent: 'center', fontWeight: 700 }}>
                                    {position + (pose ? (' - ' + pose) : '')} - {item?.variant} - {item?.rotation}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const selectedPosition = () => {
        return (
            <div>
                <select onChange={(event) => { setPosition(event.target.value) }} value={position}>
                    <option value="Eating">Eating</option>
                    <option value="Walking">Walking</option>
                    <option value="Running">Running</option>
                    <option value="Sitting">Sitting</option>
                    <option value="Standing">Standing</option>
                    <option value="Laying">Laying</option>
                </select>
            </div>
        )
    }

    const selectedPoseStanding = () => {
        return (
            <div style={{ marginLeft: 20 }} onChange={(event) => { setPose(event.target.value) }} value={pose}>
                <select >
                    <option value="Normal">Normal</option>
                    <option value="Looking Up">Looking Up</option>
                </select>
            </div>
        )
    }

    const selectedPoseLaying = () => {
        return (
            <div style={{ marginLeft: 20 }} onChange={(event) => { setPose(event.target.value) }} value={pose}>
                <select >
                    <option value="Side Sleeper">Side Sleeper</option>
                    <option value="Super Man">Super Man</option>
                    <option value="Belly Up">Belly Up</option>
                    <option value="Doughnut Left">Doughnut Left</option>
                    <option value="Doughnut Right">Doughnut Right</option>
                </select>
            </div>
        )
    }

    const renderCountMovement = () => {
        return (
            <div style={{ marginTop: 40, display: 'flex' }}>
                <div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Eating: {eating}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Walking: {walking}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Running: {running}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Sitting: {sitting}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Standing Normal: {standingN}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Standing Looking Up: {standingLU}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Laying Side Sleeper: {layingSS}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Laying Super Man: {layingSM}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Laying Belly Up: {layingBU}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Laying Doughnut Left: {layingDL}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Laying Doughnut Right: {layingDR}</div>
                </div>
                <div style={{width: 100}}></div>
                <div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Eating: {moment().startOf('day')
                        .seconds(((eating * timePer) / 3600).toFixed(0))
                        .format('H:mm:ss')}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Walking: {moment().startOf('day')
                        .seconds(((walking * timePer) / 3600).toFixed(0))
                        .format('H:mm:ss')}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Running: {moment().startOf('day')
                        .seconds(((running * timePer) / 3600).toFixed(0))
                        .format('H:mm:ss')}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Sitting: {moment().startOf('day')
                        .seconds(((sitting * timePer) / 3600).toFixed(0))
                        .format('H:mm:ss')}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Standing Normal: {moment().startOf('day')
                        .seconds(((standingN * timePer) / 3600).toFixed(0))
                        .format('H:mm:ss')}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Standing Looking Up: {moment().startOf('day')
                        .seconds(((standingLU * timePer) / 3600).toFixed(0))
                        .format('H:mm:ss')}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Laying Side Sleeper: {moment().startOf('day')
                        .seconds(((layingSS * timePer) / 3600).toFixed(0))
                        .format('H:mm:ss')}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Laying Super Man: {moment().startOf('day')
                        .seconds(((layingSM * timePer) / 3600).toFixed(0))
                        .format('H:mm:ss')}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Laying Belly Up: {moment().startOf('day')
                        .seconds(((layingBU * timePer) / 3600).toFixed(0))
                        .format('H:mm:ss')}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Laying Doughnut Left: {moment().startOf('day')
                        .seconds(((layingDL * timePer) / 3600).toFixed(0))
                        .format('H:mm:ss')}</div>
                    <div style={{ fontSize: 26, marginBottom: 6 }}>Laying Doughnut Right: {moment().startOf('day')
                        .seconds(((layingDR * timePer) / 3600).toFixed(0))
                        .format('H:mm:ss')}</div>
                </div>
            </div>
        )
    }

    const headers = [
        { label: "SIZE", key: "size" },
        { label: "MOVEMENT", key: "movement" },
        { label: "PITCH", key: "pitch" },
        { label: "ROLL", key: "roll" },
        { label: "YAW", key: "yaw" },
        { label: "ACC X", key: "accx" },
        { label: "ACC Y", key: "accy" },
        { label: "ACC Z", key: "accz" },
        { label: "VELOCITY", key: "velocity" }
    ];

    const _onClickFilter = () => {
      if (files.length > 0) {
        let dataParse = JSON.parse(files.split(','));
        if (pose) {
          let dataFilter = dataParse.filter(
            (val) => val?.POSITION === position + ' ' + pose
          );
          console.log('dataFilter: ', dataFilter);
          const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(dataFilter)
          )}`;
          const link = document.createElement('a');
          link.href = jsonString;
          link.download = `${position + '_' + pose}.json`;
          link.click();
        } else {
          let dataFilter = dataParse.filter((val) => val?.POSITION === position);
          console.log('dataFilter: ', dataFilter);
          const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(dataFilter)
          )}`;
          const link = document.createElement('a');
          link.href = jsonString;
          link.download = `${position}.json`;
          link.click();
        }
      }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, width: '100%', justifyContent: 'center', alignItems: 'center', paddingBottom: 100 }}>
            <div style={{ display: 'flex', marginTop: 30, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <Button text='Export' className='buttonSave' textColor='white' onClick={() => { onClickExport() }} />
                <Button text='Graph' className='buttonSave' textColor='white' onClick={() => { onClickGraph() }} />
                <Button text='Movement' className='buttonSave' textColor='white' onClick={() => { onClickMovement() }} />
                <Button text='Filter' className='buttonSave' textColor='white' onClick={() => { _onClickFilter() }} />
            </div>
            <div style={{ marginTop: 20, display: 'flex' }}>
                {selectedPosition()}
                {position === 'Standing' || position === 'Laying' ? (position === 'Standing' ? selectedPoseStanding() : selectedPoseLaying()) : ''}
            </div>
            <div>
                <h1>Upload Json file</h1>
                <input type="file" onChange={handleChange} />
            </div>
            <div>
                <div style={{ fontSize: 26, marginTop: 30 }}>During time: {duringTime}</div>
                {renderCountMovement()}
                {chart && renderChart(chart)}
            </div>
        </div>
    )
}
