const movementM = {
    eating: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.11, "max": 3.03 },
            "common": { "min": -3.04, "max": 2.56 },
            "avg": { "min": -2.4, "max": 2.38 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.11, "max": 2.83 },
            "common": { "min": -2.63, "max": 2.05 },
            "avg": { "min": -2.59, "max": -0.5 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -3.12, "max": 3.12 },
            "common": { "min": -3.11, "max": 2.76 },
            "avg": { "min": -2.39, "max": 2.5 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -0.487, "max": 1.0489 },
            "common": { "min": -0.487, "max": 0.7403 },
            "avg": { "min": 0.14, "max": 0.69 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -1.1613, "max": 1.1275 },
            "common": { "min": -0.9775, "max": 0.9854 },
            "avg": { "min": -0.93, "max": 0.79 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -1.2275, "max": 1.0921 },
            "common": { "min": -0.7145, "max": 0.8067 },
            "avg": { "min": -0.77, "max": 0.81 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 2, "max": 210 },
            "common": { "min": 18, "max": 82 },
            "avg": { "min": 44.88, "max": 82.5 }
        }
    },
    walking: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.13, "max": 3.15 },
            "common": { "min": -1.86, "max": 3.01 },
            "avg": { "min": -1.74, "max": 2.19 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.1, "max": 3.12 },
            "common": { "min": -0.09, "max": 2.86 },
            "avg": { "min": -0.01, "max": 1.49 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -2.99, "max": 2.76 },
            "common": { "min": -1.73, "max": 1.88 },
            "avg": { "min": -1.48, "max": 1.59 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -1.0993, "max": 0.8934 },
            "common": { "min": -0.4685, "max": 0.2237 },
            "avg": { "min": -0.25, "max": 0.03 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -1.3056, "max": 1.4048 },
            "common": { "min": -0.975, "max": 1.1192 },
            "avg": { "min": -0.99, "max": 0.96 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -1.4489, "max": 1.3436 },
            "common": { "min": -1.041, "max": 0.8013 },
            "avg": { "min": -0.99, "max": 0.81 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 1, "max": 259 },
            "common": { "min": 27, "max": 149 },
            "avg": { "min": 47.56, "max": 102.78 }
        }
    },
    running: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.1, "max": 3.03 },
            "common": { "min": -2.43, "max": 2.48 },
            "avg": { "min": -2.02, "max": 1.88 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.1, "max": 3.06 },
            "common": { "min": -2.65, "max": 2.45 },
            "avg": { "min": 0.05, "max": 1.38 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -2.46, "max": 2.97 },
            "common": { "min": -2.46, "max": 1.72 },
            "avg": { "min": -1.51, "max": 1.34 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -1.5031, "max": 0.5806 },
            "common": { "min": -0.487, "max": 0.241 },
            "avg": { "min": -0.27, "max": -0.08 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -1.9941, "max": 1.91 },
            "common": { "min": -1.3742, "max": 1.4856 },
            "avg": { "min": -1.04, "max": 1.06 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -1.6831, "max": 1.327 },
            "common": { "min": -1.351, "max": 0.5828 },
            "avg": { "min": -1.04, "max": 0.79 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 5, "max": 318 },
            "common": { "min": 44, "max": 225 },
            "avg": { "min": 100.14, "max": 193.17 }
        }
    },
    sitting: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -2.79, "max": 3.11 },
            "common": { "min": -2.13, "max": 3.02 },
            "avg": { "min": -2.05, "max": 2.75 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": 1.05, "max": 2.11 },
            "common": { "min": 1.11, "max": 1.93 },
            "avg": { "min": 1.19, "max": 1.87 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -0.69, "max": 0.36 },
            "common": { "min": -0.3, "max": 0.25 },
            "avg": { "min": -0.3, "max": 0.24 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -1.527, "max": -0.6965 },
            "common": { "min": -1.0227, "max": -0.6965 },
            "avg": { "min": -1, "max": -0.9 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -0.3452, "max": 0.649 },
            "common": { "min": -0.2463, "max": 0.5835 },
            "avg": { "min": -0.23, "max": 0.29 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -0.4833, "max": 0.4705 },
            "common": { "min": -0.4228, "max": 0.3418 },
            "avg": { "min": -0.37, "max": 0.29 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 0, "max": 132 },
            "common": { "min": 2, "max": 9 },
            "avg": { "min": 4.12, "max": 15.74 }
        }
    },
    standingNormal: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.12, "max": 3.14 },
            "common": { "min": -2.14, "max": 3.07 },
            "avg": { "min": -2.09, "max": 1.99 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.13, "max": 3.14 },
            "common": { "min": 0.37, "max": 3.09 },
            "avg": { "min": 0.41, "max": 2.69 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -1.97, "max": 1.76 },
            "common": { "min": -1.42, "max": 1.35 },
            "avg": { "min": -1.27, "max": 1.42 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -0.757, "max": 0.202 },
            "common": { "min": -0.644, "max": 0.0215 },
            "avg": { "min": -0.47, "max": -0.05 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -1.0109, "max": 1.0157 },
            "common": { "min": -0.9628, "max": 0.9271 },
            "avg": { "min": -0.96, "max": 0.95 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -0.9733, "max": 1.0369 },
            "common": { "min": -0.8784, "max": 1.0369 },
            "avg": { "min": -0.76, "max": 0.95 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 0, "max": 128 },
            "common": { "min": 4, "max": 15 },
            "avg": { "min": 5.8, "max": 20.35 }
        }
    },
    standingLookingUp: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.11, "max": 3.07 },
            "common": { "min": -2.94, "max": 2.15 },
            "avg": { "min": -2.82, "max": 2.16 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -2.46, "max": 2.68 },
            "common": { "min": 0.88, "max": 2.19 },
            "avg": { "min": 0.81, "max": 2.31 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -1.15, "max": 2.13 },
            "common": { "min": -0.87, "max": 0.79 },
            "avg": { "min": -0.84, "max": 0.81 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -0.9313, "max": 0.4854 },
            "common": { "min": -0.8083, "max": -0.6171 },
            "avg": { "min": -0.8, "max": -0.61 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -0.8762, "max": 0.9078 },
            "common": { "min": -0.7517, "max": 0.8658 },
            "avg": { "min": -0.69, "max": 0.75 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -0.9841, "max": 0.8406 },
            "common": { "min": -0.5651, "max": 0.6033 },
            "avg": { "min": -0.62, "max": 0.65 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 0, "max": 106 },
            "common": { "min": 4, "max": 29 },
            "avg": { "min": 9.29, "max": 22.53 }
        }
    },
    layingSideSleeper: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -2.99, "max": 2.97 },
            "common": { "min": -2.96, "max": 2.92 },
            "avg": { "min": -2.97, "max": 2.93 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.13, "max": 3.15 },
            "common": { "min": -3.13, "max": 0.02 },
            "avg": { "min": -2.97, "max": -0.02 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -3.1, "max": 2.97 },
            "common": { "min": -2.86, "max": 1.98 },
            "avg": { "min": -2.87, "max": 1.99 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -0.5876, "max": 0.3997 },
            "common": { "min": -0.0048, "max": 0.379 },
            "avg": { "min": 0.01, "max": 0.38 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -0.9968, "max": 0.9822 },
            "common": { "min": -0.9741, "max": 0.9727 },
            "avg": { "min": -0.95, "max": 0.94 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -1.0473, "max": 0.9834 },
            "common": { "min": -0.9924, "max": 0.969 },
            "avg": { "min": -0.99, "max": 0.98 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 1, "max": 63 },
            "common": { "min": 3, "max": 7 },
            "avg": { "min": 3.06, "max": 6.87 }
        }
    },
    layingSuperMan: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -2.42, "max": 3.12 },
            "common": { "min": -2.41, "max": 3.03 },
            "avg": { "min": -2.41, "max": 3 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.13, "max": 3.14 },
            "common": { "min": -3.11, "max": 2.72 },
            "avg": { "min": -3.1, "max": 2.73 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -2.03, "max": 2.82 },
            "common": { "min": -1.77, "max": 2.13 },
            "avg": { "min": -1.78, "max": 2.14 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -0.6289, "max": 0.3687 },
            "common": { "min": -0.0905, "max": 0.1885 },
            "avg": { "min": -0.09, "max": 0.19 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -0.9973, "max": 1.0149 },
            "common": { "min": -0.9816, "max": 0.9954 },
            "avg": { "min": -0.98, "max": 1 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -1.0122, "max": 1.0086 },
            "common": { "min": -0.9973, "max": 0.9981 },
            "avg": { "min": -1, "max": 0.98 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 1, "max": 85 },
            "common": { "min": 3, "max": 6 },
            "avg": { "min": 3.13, "max": 7.88 }
        }
    },
    layingBellyUp: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.13, "max": 3.14 },
            "common": { "min": -3.1, "max": 2.99 },
            "avg": { "min": -2.34, "max": 3.01 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.07, "max": 3.12 },
            "common": { "min": -3.03, "max": 3.12 },
            "avg": { "min": -3.04, "max": 3.1 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -3.11, "max": 3.1 },
            "common": { "min": -2.96, "max": 2.08 },
            "avg": { "min": -1.99, "max": 2.02 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -0.2932, "max": 0.2898 },
            "common": { "min": -0.0969, "max": 0.2791 },
            "avg": { "min": -0.14, "max": 0.19 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -0.9812, "max": 0.9991 },
            "common": { "min": -0.9538, "max": 0.9817 },
            "avg": { "min": -0.96, "max": 0.99 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -0.7897, "max": 1.0074 },
            "common": { "min": -0.2014, "max": 0.9883 },
            "avg": { "min": -0.19, "max": 0.99 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 0, "max": 43 },
            "common": { "min": 1, "max": 5 },
            "avg": { "min": 3.13, "max": 11.41 }
        }
    },
    layingDoughnutLeft: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -2.91, "max": 2.96 },
            "common": { "min": -2.91, "max": 2.94 },
            "avg": { "min": -2.9, "max": 2.94 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.08, "max": 3.04 },
            "common": { "min": -3.05, "max": 2.99 },
            "avg": { "min": -3.06, "max": 2.82 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -2.91, "max": 2.38 },
            "common": { "min": -2.89, "max": 2.33 },
            "avg": { "min": -2.86, "max": 2.34 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -0.0954, "max": 0.264 },
            "common": { "min": -0.0869, "max": 0.2466 },
            "avg": { "min": -0.06, "max": 0.24 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -0.9885, "max": 0.9961 },
            "common": { "min": -0.986, "max": 0.991 },
            "avg": { "min": -0.98, "max": 0.98 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -0.9841, "max": 0.9832 },
            "common": { "min": -0.9768, "max": 0.9773 },
            "avg": { "min": -0.97, "max": 0.97 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 3, "max": 27 },
            "common": { "min": 4, "max": 7 },
            "avg": { "min": 5.05, "max": 7.08 }
        }
    },
    layingDoughnutRight: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -1.05, "max": 2.99 },
            "common": { "min": -1.05, "max": 2.79 },
            "avg": { "min": -0.76, "max": 2.82 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.13, "max": 0.33 },
            "common": { "min": -3.03, "max": 0.3 },
            "avg": { "min": -3.05, "max": 0.25 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -1.69, "max": 1.92 },
            "common": { "min": -1.69, "max": 1.86 },
            "avg": { "min": -1.69, "max": 1.82 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -0.1425, "max": 0.1243 },
            "common": { "min": -0.1362, "max": 0.0892 },
            "avg": { "min": -0.13, "max": 0.09 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -0.9995, "max": 0.8682 },
            "common": { "min": -0.9848, "max": 0.8438 },
            "avg": { "min": -0.99, "max": 0.69 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -0.9667, "max": 0.991 },
            "common": { "min": -0.9655, "max": 0.991 },
            "avg": { "min": -0.96, "max": 0.94 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 2, "max": 25 },
            "common": { "min": 3, "max": 7 },
            "avg": { "min": 3, "max": 5.61 }
        }
    }
}

export default movementM
