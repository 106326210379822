const movementL = {
    eating: {
        pitch: {
            range: { min: -3.13, max: 3.14 }, common: { min: -3.06, max: 3.11 }, avg: { min: -2.52, max: 2.38 }
        },
        roll: {
            range: { min: -2.75, max: 2.05 }, common: { min: -2.4, max: 2.05 }, avg: { min: -2.43, max: -0.64 }
        },
        yaw: {
            range: { min: -3.13, max: 3.13 }, common: { min: -3.11, max: 2.97 }, avg: { min: -2.58, max: 2.5 }
        },
        accx: {
            range: { min: -0.487, max: 1.0489 }, common: { min: -0.487, max: 0.7715 }, avg: { min: 0.33, max: 0.69 }
        },
        accy: {
            range: { min: -1.041, max: 0.9954 }, common: { min: -0.9775, max: 0.9854 }, avg: { min: -0.93, max: 0.71 }
        },
        accz: {
            range: { min: -1.2275, max: 1.0037 }, common: { min: -0.7145, max: 0.837 }, avg: { min: -0.77, max: 0.78 }
        },
        velocity: {
            range: { min: 2, max: 210 }, common: { min: 18, max: 82 }, avg: { min: 38.35, max: 71.56 }
        }
    },
    walking: {
        pitch: {
            range: { min: -3.13, max: 3.15 }, common: { min: -2.11, max: 3.01 }, avg: { min: -2, max: 2.57 }
        },
        roll: {
            range: { min: -3.13, max: 3.14 }, common: { min: -0.09, max: 2.98 }, avg: { min: -0.01, max: 1.34 }
        },
        yaw: {
            range: { min: -2.99, max: 2.76 }, common: { min: -1.73, max: 1.88 }, avg: { min: -1.48, max: 1.59 }
        },
        accx: {
            range: { min: -1.0993, max: 0.8934 }, common: { min: -0.4685, max: 0.2237 }, avg: { min: -0.25, max: 0.03 }
        },
        accy: {
            range: { min: -1.3056, max: 1.4048 }, common: { min: -0.975, max: 1.1192 }, avg: { min: -0.99, max: 0.97 }
        },
        accz: {
            range: { min: -1.4489, max: 1.3436 }, common: { min: -1.041, max: 0.8013 }, avg: { min: -0.99, max: 0.82 }
        },
        velocity: {
            range: { min: 1, max: 259 }, common: { min: 27, max: 149 }, avg: { min: 47.56, max: 102.78 }
        }
    },
    running: {
        pitch: {
            range: { min: -3.1, max: 3.03 }, common: { min: -2.43, max: 2.48 }, avg: { min: -2.02, max: 1.88 }
        },
        roll: {
            range: { min: -3.1, max: 3.06 }, common: { min: -2.65, max: 2.45 }, avg: { min: 0.05, max: 1.38 }
        },
        yaw: {
            range: { min: -2.46, max: 2.97 }, common: { min: -2.46, max: 1.72 }, avg: { min: -1.51, max: 1.34 }
        },
        accx: {
            range: { min: -1.5031, max: 0.5806 }, common: { min: -0.487, max: 0.241 }, avg: { min: -0.27, max: -0.08 }
        },
        accy: {
            range: { min: -1.9941, max: 1.91 }, common: { min: -1.3742, max: 1.4856 }, avg: { min: -1.04, max: 1.06 }
        },
        accz: {
            range: { min: -1.6831, max: 1.327 }, common: { min: -1.351, max: 0.5828 }, avg: { min: -1.04, max: 0.79 }
        },
        velocity: {
            range: { min: 5, max: 318 }, common: { min: 44, max: 225 }, avg: { min: 100.14, max: 193.17 }
        }
    },
    sitting: {
        pitch: {
            range: { min: -3.14, max: 2.28 }, common: { min: -3.08, max: 2.12 }, avg: { min: -3.06, max: 2.13 }
        },
        roll: {
            range: { min: 0.48, max: 2.9 }, common: { min: 0.79, max: 2.51 }, avg: { min: 0.77, max: 2.49 }
        },
        yaw: {
            range: { min: -1.17, max: 1.01 }, common: { min: -0.84, max: 0.87 }, avg: { min: -0.84, max: 0.85 }
        },
        accx: {
            range: { min: -0.8681, max: -0.2426 }, common: { min: -0.8615, max: -0.2526 }, avg: { min: -0.83, max: -0.59 }
        },
        accy: {
            range: { min: -0.8391, max: 0.9031 }, common: { min: -0.7202, max: 0.7474 }, avg: { min: -0.72, max: 0.74 }
        },
        accz: {
            range: { min: -0.8391, max: 0.9554 }, common: { min: -0.8391, max: 0.9463 }, avg: { min: -0.67, max: 0.76 }
        },
        velocity: {
            range: { min: 1, max: 31 }, common: { min: 4, max: 8 }, avg: { min: 5.1, max: 8.45 }
        }
    },
    standingNormal: {
        pitch: {
            range: { min: -3.12, max: 3.14 }, common: { min: -2.14, max: 3.07 }, avg: { min: -2.09, max: 1.99 }
        },
        roll: {
            range: { min: -3.13, max: 3.14 }, common: { min: 0.37, max: 3.09 }, avg: { min: 0.41, max: 2.69 }
        },
        yaw: {
            range: { min: -1.97, max: 1.76 }, common: { min: -1.42, max: 1.35 }, avg: { min: -1.27, max: 1.42 }
        },
        accx: {
            range: { min: -0.757, max: 0.202 }, common: { min: -0.644, max: 0.0215 }, avg: { min: -0.47, max: -0.05 }
        },
        accy: {
            range: { min: -1.0109, max: 1.0157 }, common: { min: -0.9628, max: 0.9271 }, avg: { min: -0.96, max: 0.95 }
        },
        accz: {
            range: { min: -0.9733, max: 1.0369 }, common: { min: -0.8784, max: 1.0369 }, avg: { min: -0.76, max: 0.95 }
        },
        velocity: {
            range: { min: 0, max: 128 }, common: { min: 4, max: 15 }, avg: { min: 5.8, max: 20.35 }
        }
    },
    standingLookingUp: {
        pitch: {
            range: { min: -3.11, max: 3.07 }, common: { min: -2.94, max: 2.15 }, avg: { min: -2.82, max: 2.16 }
        },
        roll: {
            range: { min: -2.46, max: 2.68 }, common: { min: 0.88, max: 2.19 }, avg: { min: 0.81, max: 2.31 }
        },
        yaw: {
            range: { min: -1.15, max: 2.13 }, common: { min: -0.87, max: 0.79 }, avg: { min: -0.84, max: 0.81 }
        },
        accx: {
            range: { min: -0.9313, max: 0.4854 }, common: { min: -0.8083, max: -0.6171 }, avg: { min: -0.8, max: -0.61 }
        },
        accy: {
            range: { min: -0.8762, max: 0.9078 }, common: { min: -0.7517, max: 0.8658 }, avg: { min: -0.69, max: 0.75 }
        },
        accz: {
            range: { min: -0.9841, max: 0.8406 }, common: { min: -0.5651, max: 0.6033 }, avg: { min: -0.62, max: 0.65 }
        },
        velocity: {
            range: { min: 0, max: 106 }, common: { min: 4, max: 29 }, avg: { min: 9.29, max: 22.53 }
        }
    },
    layingSideSleeper: {
        pitch: {
            range: { min: -2.83, max: 2.97 }, common: { min: -2.75, max: 2.92 }, avg: { min: -2.77, max: 2.93 }
        },
        roll: {
            range: { min: -3.13, max: 3.15 }, common: { min: -3.13, max: 3.07 }, avg: { min: -2.97, max: 2.77 }
        },
        yaw: {
            range: { min: -3.1, max: 2.97 }, common: { min: -2.77, max: 1.85 }, avg: { min: -2.21, max: 1.91 }
        },
        accx: {
            range: { min: -0.5876, max: 0.2283 }, common: { min: -0.0703, max: 0.2205 }, avg: { min: -0.06, max: 0.22 }
        },
        accy: {
            range: { min: -0.9968, max: 1.0125 }, common: { min: -0.9741, max: 0.9793 }, avg: { min: -0.94, max: 0.98 }
        },
        accz: {
            range: { min: -1.0473, max: 0.9834 }, common: { min: -0.9924, max: 0.969 }, avg: { min: -0.99, max: 0.98 }
        },
        velocity: {
            range: { min: 3, max: 63 }, common: { min: 4, max: 7 }, avg: { min: 4.65, max: 10.35 }
        }
    },
    layingSuperMan: {
        pitch: {
            range: { min: -3.02, max: 3.12 }, common: { min: -3, max: 3.07 }, avg: { min: -3, max: 3.02 }
        },
        roll: {
            range: { min: -3.04, max: 3.07 }, common: { min: -3.02, max: 3.05 }, avg: { min: -3.02, max: 3.05 }
        },
        yaw: {
            range: { min: -2.78, max: 2.82 }, common: { min: -2.73, max: 2.69 }, avg: { min: -2.72, max: 2.46 }
        },
        accx: {
            range: { min: -0.6289, max: 0.3123 }, common: { min: -0.1337, max: 0.2925 }, avg: { min: -0.14, max: 0.3 }
        },
        accy: {
            range: { min: -0.9936, max: 1.0149 }, common: { min: -0.9816, max: 0.9908 }, avg: { min: -0.98, max: 1 }
        },
        accz: {
            range: { min: -1.0122, max: 0.9988 }, common: { min: -0.9973, max: 0.9617 }, avg: { min: -1, max: 0.96 }
        },
        velocity: {
            range: { min: 2, max: 85 }, common: { min: 5, max: 7 }, avg: { min: 4.75, max: 8.64 }
        }
    },
    layingBellyUp: {
        pitch: {
            "range": { "min": -2.91, "max": 2.96 },
            "common": { "min": -2.91, "max": 2.94 },
            "avg": { "min": -2.9, "max": 2.9 }
        },
        roll: {
            "range": { "min": -3.13, "max": 3.13 },
            "common": { "min": -3.1, "max": 2.99 },
            "avg": { "min": -3.11, "max": 2.82 }
        },
        yaw: {
            "range": { "min": -2.91, "max": 2.93 },
            "common": { "min": -2.89, "max": 2.33 },
            "avg": { "min": -2.86, "max": 2.03 }
        },
        accx: {
            "range": { "min": -0.0954, "max": 0.264 },
            "common": { "min": -0.0869, "max": 0.2466 },
            "avg": { "min": -0.06, "max": 0.24 }
        },
        accy: {
            "range": { "min": -1, "max": 0.9993 },
            "common": { "min": -0.9975, "max": 0.9915 },
            "avg": { "min": -0.99, "max": 0.99 }
        },
        accz: {
            "range": { "min": -0.9987, "max": 0.9832 },
            "common": { "min": -0.9982, "max": 0.9773 },
            "avg": { "min": -0.99, "max": 0.97 }
        },
        velocity: {
            "range": { "min": 3, "max": 27 },
            "common": { "min": 4, "max": 7 },
            "avg": { "min": 5.05, "max": 7.08 }
        }
    },
    layingDoughnutLeft: {
        pitch: {
            "range": {
                "min": -2.91,
                "max": 2.96
            },
            "common": {
                "min": -2.91,
                "max": 2.94
            },
            "avg": {
                "min": -2.9,
                "max": 2.9
            }
        },
        roll: {
            "range": {
                "min": -3.13,
                "max": 3.13
            },
            "common": {
                "min": -3.1,
                "max": 2.99
            },
            "avg": {
                "min": -3.11,
                "max": 2.82
            }
        },
        yaw: {
            "range": {
                "min": -2.91,
                "max": 2.93
            },
            "common": {
                "min": -2.89,
                "max": 2.33
            },
            "avg": {
                "min": -2.86,
                "max": 2.03
            }
        },
        accx: {
            "range": {
                "min": -0.0954,
                "max": 0.264
            },
            "common": {
                "min": -0.0869,
                "max": 0.2466
            },
            "avg": {
                "min": -0.06,
                "max": 0.24
            }
        },
        accy: {
            "range": {
                "min": -1,
                "max": 0.9993
            },
            "common": {
                "min": -0.9975,
                "max": 0.9915
            },
            "avg": {
                "min": -0.99,
                "max": 0.99
            }
        },
        accz: {
            "range": {
                "min": -0.9987,
                "max": 0.9832
            },
            "common": {
                "min": -0.9982,
                "max": 0.9773
            },
            "avg": {
                "min": -0.99,
                "max": 0.97
            }
        },
        velocity: {
            "range": {
                "min": 3,
                "max": 27
            },
            "common": {
                "min": 4,
                "max": 7
            },
            "avg": {
                "min": 5.05,
                "max": 7.08
            }
        }
    },
    layingDoughnutRight: {
        pitch: {
            "name": "Pitch",
            "range": { "min": -3.02, "max": 2.99 },
            "common": { "min": -3, "max": 2.79 },
            "avg": { "min": -3, "max": 2.82 }
        },
        roll: {
            "name": "Roll",
            "range": { "min": -3.13, "max": 3.12 },
            "common": { "min": -3.07, "max": 0.3 },
            "avg": { "min": -3.05, "max": 0.25 }
        },
        yaw: {
            "name": "Yaw",
            "range": { "min": -2.23, "max": 2.4 },
            "common": { "min": -2.23, "max": 2.34 },
            "avg": { "min": -2.19, "max": 2.35 }
        },
        accx: {
            "name": "AccX",
            "range": { "min": -0.2949, "max": 0.3724 },
            "common": { "min": -0.1362, "max": 0.3677 },
            "avg": { "min": -0.13, "max": 0.36 }
        },
        accy: {
            "name": "AccY",
            "range": { "min": -0.9995, "max": 0.9673 },
            "common": { "min": -0.9929, "max": 0.94 },
            "avg": { "min": -0.99, "max": 0.95 }
        },
        accz: {
            "name": "AccZ",
            "range": { "min": -0.9667, "max": 0.9991 },
            "common": { "min": -0.9655, "max": 0.9969 },
            "avg": { "min": -0.96, "max": 0.99 }
        },
        velocity: {
            "name": "Velocity",
            "range": { "min": 2, "max": 82 },
            "common": { "min": 3, "max": 7 },
            "avg": { "min": 3, "max": 9.43 }
        }
    }
}

export default movementL
