import React, { useEffect, useState } from "react";
import "./App.css";
import moment from 'moment'
import mqtt from 'mqtt'
import _ from 'lodash'
import { CSVLink } from "react-csv";

// icons and images
import record_button from './assets/record_button.png'
import ic_eating from './assets/ic_eating.png'
import ic_laying from './assets/ic_laying.png'
import ic_running from './assets/ic_running.png'
import ic_sitting from './assets/ic_sitting.png'
import ic_standing from './assets/ic_standing.png'
import ic_walking from './assets/ic_walking.png'
import mypetgo_logo from './assets/mypetgo_logo.png'
import ic_pin from './assets/ic_pin.png'
import grey_record from './assets/grey_record.png'

export default function Emulator() {
    const [rotation, setRotation] = useState(false);
    const [position, setPosition] = useState(false);
    const [variant, setVariant] = useState(false);
    const [record, setRecord] = useState(false);
    const [rotationValue, setRotationValue] = useState(0);
    const [variantValue, setVariantValue] = useState('Regular Fit');
    const [positionValue, setPositionValue] = useState('Standing');
    const [subLayingValue, setSubLayingValue] = useState();
    const [subStandingValue, setSubStandingValue] = useState('Normal');
    const [sizeValue, setSizeValue] = useState('S');
    const [breedValue, setBreedValue] = useState();
    const [ageValue, setAgeValue] = useState();
    const [weightValue, setWeightValue] = useState();
    const [genderValue, setGenderValue] = useState();
    const [monitorId, setMonitorId] = useState();
    const [lastestData, setLastestData] = useState([]);
    const [listData, setListData] = useState([]);
    const [listDataRecord, setListDataRecord] = useState([]);
    const [dataExport, setDataExport] = useState([]);
    const [dogInfo, setDogInfo] = useState();
    const [isCal, setCal] = useState(false);

    const dataLocal = localStorage.getItem('items')

    const callMQTT = () => {
        if (monitorId) {
            const host = "ws://broker.emqx.io:8083/mqtt";
            const clientId = "mqttjs_" + Math.random().toString(16).substr(2, 8);

            const options = {
                keepalive: 30,
                clientId: clientId,
                protocolId: "MQTT",
                protocolVersion: 4,
                clean: true,
                reconnectPeriod: 1000,
                connectTimeout: 30 * 1000,
                will: {
                    topic: "WillMsg",
                    payload: "Connection Closed abnormally..!",
                    qos: 0,
                    retain: false,
                },
                rejectUnauthorized: false,
            };
            console.log("connecting mqtt client");
            const client = mqtt.connect(host, options);
            client.on("error", (err) => {
                console.log("Connection error: ", err);
                client.end();
            });

            client.on("reconnect", () => {
                console.log("Reconnecting...");
            });

            client.on("connect", () => {
                console.log("Client connected:" + clientId);
                if (monitorId.length === 8) {
                    client.subscribe("/mypetgo/" + monitorId, { qos: 0 });
                    client.subscribe("/mypetgo/init/" + monitorId, { qos: 1 });
                }
            });
    
            client.on("message", (topic, message, packet) => {
                // console.log(
                //     "Received Message: " + message.toString() + "\nOn topic: " + topic
                // );
                var obj = JSON.parse(message.toString());
                console.log('obj: ', obj)
                if (!obj?.ID) {
                    setLastestData(obj)
                    listData.push(obj)
                }
            })
        }
    }

    useEffect(() => {
        callMQTT()
    }, [monitorId])

    const renderLine1RealTime = () => {
        return (
            <div>
                <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">Pitch</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">Roll</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">Yaw</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">Acc. X</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">Acc. Y</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">Acc. Z</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">Velocity</div>
                    </div>
                </div>
            </div>
        )
    };

    const renderLastestParam = () => {
        return (
            <div>
                <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.Pitch}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.Roll}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.Yaw}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.accX}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.accY}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.accY}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.Motion}</div>
                    </div>
                </div>
            </div>
        )
    }

    const renderHealthParam = () => {
        return (
            <div>
                <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">HRV</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">PULSE</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">RESPIRATION</div>
                    </div>
                </div>
            </div>
        )
    }

    const renderLastestParamHealth = () => {
        return (
            <div>
                <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.hrv}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.pul}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.rsr}</div>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (record === true) {
            convertData()
        }
    }, [record])

    const convertData = () => {
        const checkListData = listData.length > 0 ? [listData[listData.length - 1]] : []
        const concatData = _.concat(listDataRecord, checkListData)
        setListDataRecord(concatData)
        const data = []
        var item = {}
        item.diviceid = monitorId
        item.date = moment().format()
        item.size = sizeValue
        item.position = positionValue
        item.pose = subLayingValue || subStandingValue || 'N/A'
        item.rotation = rotationValue
        item.variant = variantValue
        item.pitch = listData[listData.length - 1]?.Pitch
        item.roll = listData[listData.length - 1]?.Roll
        item.yaw = listData[listData.length - 1]?.Yaw
        item.accx = listData[listData.length - 1]?.accX
        item.accy = listData[listData.length - 1]?.accY
        item.accz = listData[listData.length - 1]?.accZ
        item.velocity = listData[listData.length - 1]?.Motion
        item.breed = dogInfo?.breed
        item.age = dogInfo?.age
        item.weight = dogInfo?.weight
        item.gender = dogInfo?.gender
        item.gyrox = listData[listData.length - 1]?.gyroX
        item.gyroy = listData[listData.length - 1]?.gyroY
        item.gyroz = listData[listData.length - 1]?.gyroZ
        item.bodytemp = listData[listData.length - 1]?.Body_Temp
        item.devicetemp = listData[listData.length - 1]?.Device_Temp
        data.push(item)
        const concatDataExport = _.concat(dataExport, data)
        setDataExport(concatDataExport)
        const concatDataLocal = _.concat(JSON.parse(dataLocal) === null ? [] : JSON.parse(dataLocal), data)
        localStorage.setItem('items', JSON.stringify(concatDataLocal))
        console.log('listDataRecord: ', listDataRecord)
    }

    const renderListParam = () => {
        const listParam = []
        if ((listDataRecord || []).length > 0) {
            for (let i = 0; i < (listDataRecord).length; i++) {
                let item = {}
                item =
                    <div key={i} className="ctnParameter">
                        <div style={{ flex: 1 }}>
                            <div className={`${i % 2 === 0 ? "textParamStyle" : "textParamStyle2"}`}>{listDataRecord[i]?.Pitch}</div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div className={`${i % 2 === 0 ? "textParamStyle" : "textParamStyle2"}`}>{listDataRecord[i]?.Roll}</div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div className={`${i % 2 === 0 ? "textParamStyle" : "textParamStyle2"}`}>{listDataRecord[i]?.Yaw}</div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div className={`${i % 2 === 0 ? "textParamStyle" : "textParamStyle2"}`}>{listDataRecord[i]?.accX}</div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div className={`${i % 2 === 0 ? "textParamStyle" : "textParamStyle2"}`}>{listDataRecord[i]?.accY}</div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div className={`${i % 2 === 0 ? "textParamStyle" : "textParamStyle2"}`}>{listDataRecord[i]?.accZ}</div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div className={`${i % 2 === 0 ? "textParamStyle" : "textParamStyle2"}`}>{listDataRecord[i]?.Motion}</div>
                        </div>
                    </div>
                listParam.push(item)
            }
            // console.log('listParam: ', listParam)
            return listParam.reverse()
        }
    }

    const renderParameter = () => {
        return (
            <div className="borderStyle">
                {renderListParam()}
            </div>
        )
    }

    const checkPose = () => {
        if (positionValue === 'Laying' && subLayingValue !== undefined) {
            return '(' + subLayingValue + ')'
        }
        else if (positionValue === 'Standing') {
            return '(' + subStandingValue + ')'
        }
        else return ''
    }

    const checkRotationValue = () => {
        switch (rotationValue) {
            case 0:
                return '0° (Facing Up)'
            case 45:
                return '45° (Top Left)'
            case 90:
                return '90° (Left)'
            case 135:
                return '135° (Bottom Left)'
            case 180:
                return '180° (Facing Down)'
            case 225:
                return '225° (Bottom Right)'
            case 270:
                return '270° (Right)'
            case 315:
                return '315° (Top Right)'
            default:
                break;
        }
    }

    const renderTitleInfoEmulator = () => {
        return (
            <div style={{ flex: 1, flexDirection: 'row', display: 'flex', marginTop: 16 }}>
                <div style={{ flex: 1 }}>
                    <div className="ctnTitleInfoEmu">Size</div>
                    <div className="ctnInfoEmu">{sizeValue}</div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className="ctnTitleInfoEmu">Position</div>
                    <div className="ctnInfoEmu">{positionValue} {checkPose()}</div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className="ctnTitleInfoEmu">Rotation</div>
                    <div className="ctnInfoEmu">{checkRotationValue()}</div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className="ctnTitleInfoEmu">Variant</div>
                    <div className="ctnInfoEmu">{variantValue}</div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className="ctnTitleInfoEmu">Date</div>
                    <div className="ctnInfoEmu">{moment().format('DD MMM YYYY')}</div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className="ctnTitleInfoEmu">Monitor</div>
                    <div className="ctnInfoEmu">{monitorId}</div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className="ctnTitleInfoEmu"></div>
                    <div className="ctnInfoEmu"></div>
                </div>
            </div>
        )
    }

    const _onClickButton = (title) => {
        setRotation(false)
        setPosition(false)
        setVariant(false)
        if (title === 'Rotation') {
            setRotation(true)
        }
        else if (title === 'Position') {
            setPosition(true)
        }
        else if (title === 'Variant') {
            setVariant(true)
        }
    }

    const checkOnOff = (title) => {
        if (title === 'Rotation' && rotation) {
            return true
        }
        else if (title === 'Position' && position) {
            return true
        }
        else if (title === 'Variant' && variant) {
            return true
        }
        else {
            return false
        }
    }

    const buttonItem = (title) => {
        return (
            <div onClick={() => { _onClickButton(title) }} className="buttonControlStyle">
                <div className="textButtonControlStyle">{title}</div>
                {checkOnOff(title) ? <div className="circleButtonClick"></div>
                    : <div className="circleButton"></div>}
            </div>
        )
    }

    const checkRecordButtonColor = () => {
        if (breedValue && ageValue && weightValue && genderValue && sizeValue && record === false) {
            return record_button
        } else if (record) {
            return grey_record
        } else {
            return grey_record
        }
    }

    const _onClickRecord = () => {
        // const params = new URLSearchParams(window.location.search);
        // const id = params.get('id')
        // setMonitorId(id)
        setRecord(true)
        setTimeout(() => {
            setRecord(false)
        }, 1500);
    }

    const renderButtonControl = () => {
        return (
            <div className="ctnButtonControl">
                <div style={{cursor:'pointer'}} onClick={() => {_onClickRecord()}}>
                    <img src={checkRecordButtonColor()} style={{ height: 190, width: 190 }} />
                </div>
                <div style={{ width: 56 }} />
                {buttonItem('Rotation')}
                <div style={{ width: 56 }} />
                {buttonItem('Position')}
                <div style={{ width: 56 }} />
                {buttonItem('Variant')}
            </div>
        )
    }

    const _onClickItemRotation = (rotation) => {
        setRotationValue(rotation)
    }

    const renderRotation = () => {
        return (
            <div className="ctnRotation">
                <div onClick={() => { _onClickItemRotation(0) }} className={`${rotationValue === 0 ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    0°
                </div>
                <div className="deviderStyle" />
                <div onClick={() => { _onClickItemRotation(45) }} className={`${rotationValue === 45 ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    45°
                </div>
                <div className="deviderStyle" />
                <div onClick={() => { _onClickItemRotation(90) }} className={`${rotationValue === 90 ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    90°
                </div>
                <div className="deviderStyle" />
                <div onClick={() => { _onClickItemRotation(135) }} className={`${rotationValue === 135 ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    135°
                </div>
                <div className="deviderStyle" />
                <div onClick={() => { _onClickItemRotation(180) }} className={`${rotationValue === 180 ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    180°
                </div>
                <div className="deviderStyle" />
                <div onClick={() => { _onClickItemRotation(225) }} className={`${rotationValue === 225 ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    225°
                </div>
                <div className="deviderStyle" />
                <div onClick={() => { _onClickItemRotation(270) }} className={`${rotationValue === 270 ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    270°
                </div>
                <div className="deviderStyle" />
                <div onClick={() => { _onClickItemRotation(315) }} className={`${rotationValue === 315 ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    315°
                </div>
            </div>
        )
    }

    const _onClickItemVariant = (rotation) => {
        setVariantValue(rotation)
    }

    const renderVariant = () => {
        return (
            <div className="ctnVariant">
                <div onClick={() => { _onClickItemVariant('Regular Fit') }} className={`${variantValue === 'Regular Fit' ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    REGULAR FIT
                </div>
                <div className="deviderStyle" />
                <div onClick={() => { _onClickItemVariant('Loose Fit') }} className={`${variantValue === 'Loose Fit' ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    LOOSE FIT
                </div>
            </div>
        )
    }

    const _onClickItemPosition = (title) => {
        setSubLayingValue()
        setSubStandingValue()
        setPositionValue(title)
    }

    const renderItemPositon = (title, icon) => {
        return (
            <div onClick={() => { _onClickItemPosition(title) }} className="ctnItemPosition">
                <img src={icon} style={{ height: 40, width: 40 }} />
                <p className="titlePositionStyle">{title}</p>
                {positionValue === title ? <div className="circleButtonClick"></div> : <div className="circleButton"></div>}
            </div>
        )
    }

    const renderPosition = () => {
        return (
            <div className="ctnPostion">
                {renderItemPositon('Eating', ic_eating)}
                {renderItemPositon('Walking', ic_walking)}
                {renderItemPositon('Running', ic_running)}
                {renderItemPositon('Sitting', ic_sitting)}
                {renderItemPositon('Standing', ic_standing)}
                {renderItemPositon('Laying', ic_laying)}
            </div>
        )
    }

    const _onClickSubLaying = (title) => {
        setSubLayingValue(title)
    }

    const renderSubLaying = () => {
        return (
            <div className="ctnSubLaying">
                <div onClick={() => { _onClickSubLaying('Side Sleeper') }} className={`${subLayingValue === 'Side Sleeper' ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    SIDE SLEEPER
                </div>
                <div className="deviderStyle" />
                <div onClick={() => { _onClickSubLaying('Super Man') }} className={`${subLayingValue === 'Super Man' ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    SUPER MAN
                </div>
                <div className="deviderStyle" />
                <div onClick={() => { _onClickSubLaying('Belly Up') }} className={`${subLayingValue === 'Belly Up' ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    BELLY UP
                </div>
                <div className="deviderStyle" />
                <div onClick={() => { _onClickSubLaying('Doughnut Left') }} className={`${subLayingValue === 'Doughnut Left' ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    DOUGHNUT LEFT
                </div>
                <div className="deviderStyle" />
                <div onClick={() => { _onClickSubLaying('Doughnut Right') }} className={`${subLayingValue === 'Doughnut Right' ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    DOUGHNUT RIGHT
                </div>
            </div>
        )
    }

    const _onClickItemStanding = (rotation) => {
        setSubStandingValue(rotation)
    }

    const renderSubStanding = () => {
        return (
            <div className="ctnSubStanding">
                <div onClick={() => { _onClickItemStanding('Normal') }} className={`${subStandingValue === 'Normal' ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    NORMAL
                </div>
                <div className="deviderStyle" />
                <div onClick={() => { _onClickItemStanding('Looking Up') }} className={`${subStandingValue === 'Looking Up' ? "rotationTextClickStyle" : "rotationTextStyle"}`}>
                    LOOKING UP
                </div>
            </div>
        )
    }

    const renderSubSelectionView = () => {
        return (
            <div>
                <div className="ctnSelectionView">
                    {rotation && renderRotation()}
                    {variant && renderVariant()}
                    {position && renderPosition()}
                </div>
                <div className="ctnSubPositionView">
                    {(positionValue === 'Laying' && position) && renderSubLaying()}
                    {(positionValue === 'Standing' && position) && renderSubStanding()}
                </div>
            </div>
        )
    }

    const Button = ({ text, className, pin, textColor, onClick }) => {
        return (
            <div onClick={onClick} className={className}>
                {pin && <img src={ic_pin} style={{ height: 24, width: 24 }} />}
                <p style={{ fontSize: 16, fontWeight: 700, color: textColor }}>{text}</p>
            </div>
        )
    }

    const renderTextInput = (placeholder, className, value, setValue) => {
        return (
            <div>
                <input onChange={(txt) => setValue(txt?.target?.value)} value={value} className={className} placeholder={placeholder} />
            </div>
        )
    }

    const renderBottom = () => {
        return (
            <div className="ctnBottom">
                <div className="ctnLogo">
                    <img src={mypetgo_logo} style={{ height: 24, width: 110, marginRight: 18 }} />
                    <Button text={lastestData?.Battery ? (lastestData?.Battery + '%') : ''} className="classPin" pin={true} />
                </div>
                <div className="ctnLogo">
                    {renderTextInput('Breed', 'textInputBreed', breedValue, setBreedValue)}
                    {renderTextInput('Age(yrs)', 'textInputAge', ageValue, setAgeValue)}
                    {renderTextInput('Weight (kg)', 'textInputWeight', weightValue, setWeightValue)}
                    {renderTextInput('Gender', 'textInputAge', genderValue, setGenderValue)}
                    {renderTextInput('Size', 'textInputAge', sizeValue, setSizeValue)}
                    <Button text='Save' className='buttonSave' textColor='white' onClick={_onClickSave} />
                    <div style={{width: 8}} />
                    <CSVLink filename={`${moment().format('YYYY-MM-DD_H-mm-SS')}_mpg_data.csv`} style={{ fontSize: 16, fontWeight: 700, color: '#4C06B7' }} className="buttonExport" data={dataExport} headers={headers}>
                        Export
                    </CSVLink>
                    <CSVLink filename={`${moment().format('YYYY-MM-DD_H-mm-SS')}_mpg_data_all.csv`} style={{ fontSize: 16, fontWeight: 700, color: '#4C06B7' }} className="buttonExport" data={JSON.parse(dataLocal) || []} headers={headers}>
                        Export all data
                    </CSVLink>
                </div>
            </div>
        )
    }

    const _onClickSave = () => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id')
        setMonitorId(id)
        setDogInfo({
            size: sizeValue,
            gender: genderValue,
            weight: weightValue,
            age: ageValue,
            breed: breedValue,
        })
    }

    const headers = [
        { label: "DEVICE ID", key: "diviceid" },
        { label: "DATE", key: "date" },
        { label: "SIZE", key: "size" },
        { label: "POSITION", key: "position" },
        { label: "POSE", key: "pose" },
        { label: "ROTATION", key: "rotation" },
        { label: "VARIANT", key: "variant" },
        { label: "PITCH", key: "pitch" },
        { label: "ROLL", key: "roll" },
        { label: "YAW", key: "yaw" },
        { label: "ACC X", key: "accx" },
        { label: "ACC Y", key: "accy" },
        { label: "ACC Z", key: "accz" },
        { label: "GYRO X", key: "gyrox" },
        { label: "GYRO Y", key: "gyroy" },
        { label: "GYRO Z", key: "gyroz" },
        { label: "VELOCITY", key: "velocity" },
        { label: "BODY TEMP", key: "bodytemp" },
        { label: "DEVICE TEMP", key: "devicetemp" },
        { label: "BREED", key: "breed" },
        { label: "AGE", key: "age" },
        { label: "WEIGHT", key: "weight" },
        { label: "GENDER", key: "gender" },
    ];

    const Emulator = () => {
        return (
            <div className="container">
                <div>
                    {renderLine1RealTime()}
                    {lastestData && renderLastestParam()}
                    {renderParameter()}
                    {renderTitleInfoEmulator()}
                    {renderButtonControl()}
                    {renderSubSelectionView()}
                </div>
                <div>
                    {renderBottom()}
                </div>
            </div>
        )
    }

    return (
        <div className="container">
            <div>
                {renderLine1RealTime()}
                {lastestData && renderLastestParam()}
                {renderHealthParam()}
                {lastestData && renderLastestParamHealth()}
                {renderParameter()}
                {renderTitleInfoEmulator()}
                {renderButtonControl()}
                {renderSubSelectionView()}
            </div>
            <div>
                {renderBottom()}
            </div>
        </div>
    )
}
