const movementXL = {
    eating: {
        pitch: {
            range: {min: -3.12, max: 3.11}, common: {min: -3.09, max: 2.54}, avg: {min: -2.34, max: 2.11}
        },
        roll: {
            range: {min: -2.8, max: 1.98}, common: {min: -2.29, max: 1.98}, avg: {min: -2.19, max: -0.37}
        },
        yaw: {
            range: {min: -3.13, max: 3.14}, common: {min: -2.57, max: 3.06}, avg: {min: -2.52, max: 2.46}
        },
        accx: {
            range: {min: -0.8647, max: 1.3897}, common: {min: -0.8647, max: 0.9666}, avg: {min: -0.02, max: 0.76}
        },
        accy: {
            range: {min: -1.4484, max: 1.1971}, common: {min: -0.8449, max: 0.8353}, avg: {min: -0.79, max: 0.71}
        },
        accz: {
            range: {min: -1.4143, max: 1.1036}, common: {min: -1.0346, max: 0.6336}, avg: {min: -0.83, max: 0.56}
        },
        velocity: {
            range: {min: 2, max: 322}, common: {min: 17, max: 179}, avg: {min: 31.47, max: 111}
        }
    },
    walking: {
        pitch: {
            range: {min: -3.1, max: 3.14}, common: {min: -2.53, max: 3}, avg: {min: -2.18, max: 2.07}
        },
        roll: {
            range: { min: -3.1, max: 3.08 }, common: { min: 0.23, max: 2.91 }, avg: { min: 0.32, max: 2.5 }
        },
        yaw: {
            range: { min: -1.97, max: 2.75}, common: { min: -1.11, max: 1.3 }, avg: { min: -1.19, max: 1.34 }
        },
        accx: {
            range: {min: -1.362, max: 0.8362}, common: {min: -1.0546, max: -0.1181}, avg: {min: -0.74, max: -0.19}
        },
        accy: {
            range: {min: -1.277, max: 1.2063}, common: {min: -1.0656, max: 0.8035}, avg: {min: -0.92, max: 0.88}
        },
        accz: {
            range: {min: -1.0903, max: 1.1041}, common: {min: -1.0368, max: 0.982}, avg: {min: -0.77, max: 0.8}
        },
        velocity: {
            range: {min: 5, max: 363}, common: {min: 16, max: 128}, avg: {min: 36.55, max: 76}
        }
    },
    running: {
        pitch: {
            range: {min: -3.12, max: 3.14}, common: {min: -2.96, max: 1.34}, avg: {min: -1.87, max: 1.15}
        },
        roll: {
            range: {min: -3.13, max: 3.12}, common: {min: -0.32, max: 2.15}, avg: {min: 0.38, max: 2.18}
        },
        yaw: {
            range: {min: -3.14, max: 2.81}, common: {min: -3.04, max: 1.6}, avg: {min: -1.09, max: 1.27}
        },
        accx: {
            range: {min: -1.7746, max: 2}, common: {min: -0.9311, max: 2}, avg: {min: -0.62, max: -0.33}
        },
        accy: {
            range: {min: -1.4304, max: 1.7144}, common: {min: -1.049, max: 0.7793}, avg: {min: -0.83, max: 0.85}
        },
        accz: {
            range: {min: -1.5881, max: 1.9932}, common: {min: -0.9467, max: 1.0469}, avg: {min: -0.8, max: 0.51}
        },
        velocity: {
            range: {min: 5, max: 433}, common: {min: 40, max: 433}, avg: {min: 89.52, max: 141.2}
        }
    },
    sitting: {
        pitch: {
            range: {min: -3.05, max: 3.1}, common: {min: -2.5, max: 2.55}, avg: {min: -2.49, max: 2.48}
        },
        roll: {
            range: {min: -3.07, max: 3.09},common: {min: -3.07, max: 2.19}, avg: {min: -1.04, max: 2.05}
        },
        yaw: {
            range: {min: -1.49, max: 1.68}, common: {min: -0.44, max: 1.68}, avg: {min: -0.53, max: 1.1}
        },
        accx: {
            range: {min: -1.257, max: 0.0582}, common: {min: -1.009, max: 0.0582}, avg: {min: -0.94, max: -0.3}
        },
        accy: {
            range: {min: -0.8542, max: 0.8523}, common: {min: -0.6567, max: 0.4144}, avg: {min: -0.69, max: 0.47}
        },
        accz: {
            range: {min: -0.8835, max: 0.8523}, common: {min: -0.6313, max: 0.8384}, avg: {min: -0.64, max: 0.69}
        },
        velocity: {
            range: {min: 1, max: 172}, common: {min: 4, max: 19}, avg: {min: 4.8, max: 19.1}

        }
    },
    standingNormal: {
        pitch: {
            range: {min: -3.12, max: 3.14}, common: {min: -3.09, max: 2.94}, avg: {min: -2.18, max: 2.55}
        },
        roll: {
            range: {min: -3.07, max: 3.01}, common: {min: -3.07, max: 2.68}, avg: {min: -3.07, max: 2.59}
        },
        yaw: {
            range: {min: -2.79, max: 2.3}, common: {min: -1.2, max: 1.92}, avg: {min: -1.18, max: 1.68}
        },
        accx: {
            range: {min: -0.8559, max: 0.7855}, common: {min: -0.8144, max: 0.3311}, avg: {min: -0.75, max: 0.06}
        },
        accy: {
            range: {min: -1.0295, max: 1.0162}, common: {min: -0.7839, max: 1.0162}, avg: {min: -0.76, max: 0.87}
        },
        accz: {
            range: {min: -0.8681, max: 0.9788}, common: {min: -0.6062, max: 0.9788}, avg: {min: -0.55, max: 0.84}
        },
        velocity: {
            range: {min: 0, max: 253}, common: {min: 2, max: 14}, avg: {min: 5.75, max: 43.56}
        }
    },
    standingLookingUp: {
        pitch: {
            range: {min: -3.1, max: 3.09}, common: {min: -2.94, max: 3.06}, avg: {min: -2.17, max: 2.8}
        },
        roll: {
            range: {min: 0.21, max: 3.01}, common: {min: 0.71, max: 2.14}, avg: {min: 0.78, max: 2.26}
        },
        yaw: {
            range: {min: -1.2, max: 1.4}, common: {min: -0.57, max: 1.4}, avg: {min: -0.62, max: 0.88}
        },
        accx: {
            range: {min: -1.1508, max: -0.1508}, common: {min: -0.9375, max: -0.1748}, avg: {min: -0.9, max: -0.58}
        },
        accy: {
            range: {min: -0.9567, max: 1.336}, common: {min: -0.9567, max: 1.2002}, avg: {min: -0.69, max: 0.6}
        },
        accz: {
            range: {min: -0.7602, max: 1.0582}, common: {min: -0.7492, max: 0.5716}, avg: {min: -0.65, max: 0.61}
        },
        velocity: {
            range: {min: 0, max: 151}, common: {min: 2, max: 14}, avg: {min: 4.59, max: 22.08}
        }
    },
    layingSideSleeper: {
        pitch: {
            range: {min: -3.08, max: 2.94}, common: {min: -3.07, max: 2.94}, avg: {min: -3.07, max: 2.94}
        },
        roll: {
            range: {min: -3.14, max: 3.14}, common: {min: -3.13, max: 3.14}, avg: {min: -3.06, max: 1.26}
        },
        yaw: {
            range: {min: -2.65, max: 2.13}, common: {min: -2.53, max: 2.12}, avg: {min: -2.53, max: 2.11}
        },
        accx: {
            range: {min: -0.9045, max: 0.2737}, common: {min: -0.9045, max: 0.2486}, avg: {min: -0.31, max: 0.25}
        },
        accy: {
            range: {min: -0.9187, max: 0.9983}, common: {min: -0.8781, max: 0.9603}, avg: {min: -0.88, max: 0.96}
        },
        accz: {
            range: {min: -0.9343, max: 1.0032}, common: {min: -0.9323, max: 0.9854}, avg: {min: -0.93, max: 0.99}
        },
        velocity: {
            range: {min: 1, max: 67}, common: {min: 3, max: 67}, avg: {min: 3.04, max: 26.63}
        }
    },
    layingSuperMan: {
        pitch: {
            range: {min: -3.07, max: 3.14}, common: {min: -2.94, max: 3.13}, avg: {min: -2.97, max: 2.1}
        },
        roll: {
            range: {min: -3.11, max: 2.76}, common: {min: -3.02, max: 2.1}, avg: {min: -3.01, max: 2.23}
        },
        yaw: {
            range: {min: -2.88, max: 2.59}, common: {min: -2.81, max: 2.12}, avg: {min: -2.27, max: 1.64}
        },
        accx: {
            range: {min: -0.9812, max: 0.2178}, common: {min: -0.8159, max: 0.1988}, avg: {min: -0.89, max: 0.15}
        },
        accy: {
            range: {min: -1.0153, max: 0.9996}, common: {min: -0.986, max: 0.9935}, avg: {min: -0.91, max: 0.99}
        },
        accz: {
            range: {min: -0.9997, max: 0.9893}, common: {min: -0.9907, max: 0.9803}, avg: {min: -0.92, max: 0.98}
        },
        velocity: {
            range: {min: 2, max: 75}, common: {min: 2, max: 12}, avg: {min: 4.12, max: 13}
        }
    },
    layingBellyUp: {},
    layingDoughnutLeft: {
        pitch: {
            range: {min: -3.13, max: 2.89}, common: {min: -3.11, max: 2.89}, avg: {min: -3.1, max: 2.83}
        },
        roll: {
            range: {min: -3.13, max: 3.15}, common: {min: -3.12, max: 2.92}, avg: {min: -3.05, max: 2.91}
        },
        yaw: {
            range: {min: -3.12, max: 3.09}, common: {min: -2.95, max: 3.02}, avg: {min: -2.89, max: 3.02}
        },
        accx: {
            range: {min: -0.093, max: 0.3187}, common: {min: -0.0371, max: 0.3187}, avg: {min: -0.04, max: 0.27}
        },
        accy: {
            range: {min: -0.9987, max: 0.9969}, common: {min: -0.9987, max: 0.9922}, avg: {min: -0.95, max: 0.99}
        },
        accz: {
            range: {min: -1.0144, max: 1.0132}, common: {min: -0.9887, max: 1.0096}, avg: {min: -0.99, max: 1}
        },
        velocity: {
            range: {min: 2, max: 38}, commo: {min: 3, max: 6}, avg: {min: 3.55, max: 5.8}
        }
    },
    layingDoughnutRight: {
        pitch: {
            range: {min: -2.48, max: 2.71}, common: {min: -2.48, max: 2.69}, avg: {min: -2.26, max: 2.69}
        },
        roll: {
            range: {min: -3.13, max: 3.14}, common: {min: -3.1, max: 2.95}, avg: {min: -3.1, max: 2.25}
        },
        yaw: {
            range: {min: -1.89, max: 2.25}, common: {min: -1.87, max: 2.24}, avg: {min: -1.69, max: 2.24}
        },
        accx: {
            range: {min: -0.1474, max: 0.3304}, common: {min: -0.1474, max: 0.3206}, avg: {min: -0.06, max: 0.32}
        },
        accy: {
            range: {min: -1.0673, max: 1.2259}, common: {min: -0.9609, max: 0.9617}, avg: {min: -0.99, max: 0.97}
        },
        accz: {
            range: {min: -0.9916, max: 0.8667}, common: {min: -0.9902, max: 0.8531}, avg: {min: -0.99, max: 0.85}
        },
        velocity: {
            range: {min: 0, max: 78}, common: {min: 2, max: 66}, avg: {min: 2, max: 25.38}
        }
    }
}

export default movementXL
