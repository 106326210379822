import React, { useEffect, useState } from "react";
import "./App.css";
import moment from 'moment'
import mqtt from 'mqtt'
import _ from 'lodash'
import modelXL from './modelXL'
import modelL from './modelL'
import modelM from './modelM'

// icons and images
import mypetgo_logo from './assets/mypetgo_logo.png'
import ic_pin from './assets/ic_pin.png'

export default function Movement() {
    const [sizeValue, setSizeValue] = useState('XL');
    const [breedValue, setBreedValue] = useState();
    const [ageValue, setAgeValue] = useState();
    const [weightValue, setWeightValue] = useState();
    const [genderValue, setGenderValue] = useState();
    const [monitorId, setMonitorId] = useState();
    const [lastestData, setLastestData] = useState([]);
    const [listData, setListData] = useState([]);
    const [listDataRecord, setListDataRecord] = useState([]);
    const [timeStart, setTimeStart] = useState()

    const checkMovementXl = () => {
        const checkPitchEating = _.inRange(lastestData?.Pitch, modelXL?.eating?.pitch?.common?.min, modelXL?.eating?.pitch?.common?.max);
        const checkRollEating = _.inRange(lastestData?.Roll, modelXL?.eating?.roll?.common?.min, modelXL?.eating?.roll?.common?.max);
        const checkYawEating = _.inRange(lastestData?.Yaw, modelXL?.eating?.yaw?.common?.min, modelXL?.eating?.yaw?.common?.max);
        const checkAccXEating = _.inRange(lastestData?.accX, modelXL?.eating?.accx?.common?.min, modelXL?.eating?.accx?.common?.max);
        const checkAccYEating = _.inRange(lastestData?.accY, modelXL?.eating?.accy?.common?.min, modelXL?.eating?.accy?.common?.max);
        const checkAccZEating = _.inRange(lastestData?.accZ, modelXL?.eating?.accz?.common?.min, modelXL?.eating?.accz?.common?.max);
        const checkVelEating = _.inRange(lastestData?.Motion, modelXL?.eating?.velocity?.common?.min, modelXL?.eating?.velocity?.common?.max);

        const checkPitchWalking = _.inRange(lastestData?.Pitch, modelXL?.walking?.pitch?.common?.min, modelXL?.walking?.pitch?.common?.max);
        const checkRollWalking = _.inRange(lastestData?.Roll, modelXL?.walking?.roll?.common?.min, modelXL?.walking?.roll?.common?.max);
        const checkYawWalking = _.inRange(lastestData?.Yaw, modelXL?.walking?.yaw?.common?.min, modelXL?.walking?.yaw?.common?.max);
        const checkAccXWalking = _.inRange(lastestData?.accX, modelXL?.walking?.accx?.common?.min, modelXL?.walking?.accx?.common?.max);
        const checkAccYWalking = _.inRange(lastestData?.accY, modelXL?.walking?.accy?.common?.min, modelXL?.walking?.accy?.common?.max);
        const checkAccZWalking = _.inRange(lastestData?.accZ, modelXL?.walking?.accz?.common?.min, modelXL?.walking?.accz?.common?.max);
        const checkVelWalking = _.inRange(lastestData?.Motion, modelXL?.walking?.velocity?.common?.min, modelXL?.walking?.velocity?.common?.max);

        const checkPitchRunning = _.inRange(lastestData?.Pitch, modelXL?.running?.pitch?.common?.min, modelXL?.running?.pitch?.common?.max);
        const checkRollRunning = _.inRange(lastestData?.Roll, modelXL?.running?.roll?.common?.min, modelXL?.running?.roll?.common?.max);
        const checkYawRunning = _.inRange(lastestData?.Yaw, modelXL?.running?.yaw?.common?.min, modelXL?.running?.yaw?.common?.max);
        const checkAccXRunning = _.inRange(lastestData?.accX, modelXL?.running?.accx?.common?.min, modelXL?.running?.accx?.common?.max);
        const checkAccYRunning = _.inRange(lastestData?.accY, modelXL?.running?.accy?.common?.min, modelXL?.running?.accy?.common?.max);
        const checkAccZRunning = _.inRange(lastestData?.accZ, modelXL?.running?.accz?.common?.min, modelXL?.running?.accz?.common?.max);
        const checkVelRunning = _.inRange(lastestData?.Motion, modelXL?.running?.velocity?.common?.min, modelXL?.running?.velocity?.common?.max);

        const checkPitchSitting = _.inRange(lastestData?.Pitch, modelXL?.sitting?.pitch?.common?.min, modelXL?.sitting?.pitch?.common?.max);
        const checkRollSitting = _.inRange(lastestData?.Roll, modelXL?.sitting?.roll?.common?.min, modelXL?.sitting?.roll?.common?.max);
        const checkYawSitting = _.inRange(lastestData?.Yaw, modelXL?.sitting?.yaw?.common?.min, modelXL?.sitting?.yaw?.common?.max);
        const checkAccXSitting = _.inRange(lastestData?.accX, modelXL?.sitting?.accx?.common?.min, modelXL?.sitting?.accx?.common?.max);
        const checkAccYSitting = _.inRange(lastestData?.accY, modelXL?.sitting?.accy?.common?.min, modelXL?.sitting?.accy?.common?.max);
        const checkAccZSitting = _.inRange(lastestData?.accZ, modelXL?.sitting?.accz?.common?.min, modelXL?.sitting?.accz?.common?.max);
        const checkVelSitting = _.inRange(lastestData?.Motion, modelXL?.sitting?.velocity?.common?.min, modelXL?.sitting?.velocity?.common?.max);

        const checkPitchSN = _.inRange(lastestData?.Pitch, modelXL?.standingNormal?.pitch?.common?.min, modelXL?.standingNormal?.pitch?.common?.max);
        const checkRollSN = _.inRange(lastestData?.Roll, modelXL?.standingNormal?.roll?.common?.min, modelXL?.standingNormal?.roll?.common?.max);
        const checkYawSN = _.inRange(lastestData?.Yaw, modelXL?.standingNormal?.yaw?.common?.min, modelXL?.standingNormal?.yaw?.common?.max);
        const checkAccXSN = _.inRange(lastestData?.accX, modelXL?.standingNormal?.accx?.common?.min, modelXL?.standingNormal?.accx?.common?.max);
        const checkAccYSN = _.inRange(lastestData?.accY, modelXL?.standingNormal?.accy?.common?.min, modelXL?.standingNormal?.accy?.common?.max);
        const checkAccZSN = _.inRange(lastestData?.accZ, modelXL?.standingNormal?.accz?.common?.min, modelXL?.standingNormal?.accz?.common?.max);
        const checkVelSN = _.inRange(lastestData?.Motion, modelXL?.standingNormal?.velocity?.common?.min, modelXL?.standingNormal?.velocity?.common?.max);

        const checkPitchSLU = _.inRange(lastestData?.Pitch, modelXL?.standingLookingUp?.pitch?.common?.min, modelXL?.standingLookingUp?.pitch?.common?.max);
        const checkRollSLU = _.inRange(lastestData?.Roll, modelXL?.standingLookingUp?.roll?.common?.min, modelXL?.standingLookingUp?.roll?.common?.max);
        const checkYawSLU = _.inRange(lastestData?.Yaw, modelXL?.standingLookingUp?.yaw?.common?.min, modelXL?.standingLookingUp?.yaw?.common?.max);
        const checkAccXSLU = _.inRange(lastestData?.accX, modelXL?.standingLookingUp?.accx?.common?.min, modelXL?.standingLookingUp?.accx?.common?.max);
        const checkAccYSLU = _.inRange(lastestData?.accY, modelXL?.standingLookingUp?.accy?.common?.min, modelXL?.standingLookingUp?.accy?.common?.max);
        const checkAccZSLU = _.inRange(lastestData?.accZ, modelXL?.standingLookingUp?.accz?.common?.min, modelXL?.standingLookingUp?.accz?.common?.max);
        const checkVelSLU = _.inRange(lastestData?.Motion, modelXL?.standingLookingUp?.velocity?.common?.min, modelXL?.standingLookingUp?.velocity?.common?.max);

        const checkPitchLayingSS = _.inRange(lastestData?.Pitch, modelXL?.layingSideSleeper?.pitch?.common?.min, modelXL?.layingSideSleeper?.pitch?.common?.max);
        const checkRollLayingSS = _.inRange(lastestData?.Roll, modelXL?.layingSideSleeper?.roll?.common?.min, modelXL?.layingSideSleeper?.roll?.common?.max);
        const checkYawLayingSS = _.inRange(lastestData?.Yaw, modelXL?.layingSideSleeper?.yaw?.common?.min, modelXL?.layingSideSleeper?.yaw?.common?.max);
        const checkAccXLayingSS = _.inRange(lastestData?.accX, modelXL?.layingSideSleeper?.accx?.common?.min, modelXL?.layingSideSleeper?.accx?.common?.max);
        const checkAccYLayingSS = _.inRange(lastestData?.accY, modelXL?.layingSideSleeper?.accy?.common?.min, modelXL?.layingSideSleeper?.accy?.common?.max);
        const checkAccZLayingSS = _.inRange(lastestData?.accZ, modelXL?.layingSideSleeper?.accz?.common?.min, modelXL?.layingSideSleeper?.accz?.common?.max);
        const checkVelLayingSS = _.inRange(lastestData?.Motion, modelXL?.layingSideSleeper?.velocity?.common?.min, modelXL?.layingSideSleeper?.velocity?.common?.max);

        const checkPitchLayingSM = _.inRange(lastestData?.Pitch, modelXL?.layingSuperMan?.pitch?.common?.min, modelXL?.layingSuperMan?.pitch?.common?.max);
        const checkRollLayingSM = _.inRange(lastestData?.Roll, modelXL?.layingSuperMan?.roll?.common?.min, modelXL?.layingSuperMan?.roll?.common?.max);
        const checkYawLayingSM = _.inRange(lastestData?.Yaw, modelXL?.layingSuperMan?.yaw?.common?.min, modelXL?.layingSuperMan?.yaw?.common?.max);
        const checkAccXLayingSM = _.inRange(lastestData?.accX, modelXL?.layingSuperMan?.accx?.common?.min, modelXL?.layingSuperMan?.accx?.common?.max);
        const checkAccYLayingSM = _.inRange(lastestData?.accY, modelXL?.layingSuperMan?.accy?.common?.min, modelXL?.layingSuperMan?.accy?.common?.max);
        const checkAccZLayingSM = _.inRange(lastestData?.accZ, modelXL?.layingSuperMan?.accz?.common?.min, modelXL?.layingSuperMan?.accz?.common?.max);
        const checkVelLayingSM = _.inRange(lastestData?.Motion, modelXL?.layingSuperMan?.velocity?.common?.min, modelXL?.layingSuperMan?.velocity?.common?.max);

        const checkPitchLayingBU = _.inRange(lastestData?.Pitch, modelXL?.layingBellyUp?.pitch?.common?.min, modelXL?.layingBellyUp?.pitch?.common?.max);
        const checkRollLayingBU = _.inRange(lastestData?.Roll, modelXL?.layingBellyUp?.roll?.common?.min, modelXL?.layingBellyUp?.roll?.common?.max);
        const checkYawLayingBU = _.inRange(lastestData?.Yaw, modelXL?.layingBellyUp?.yaw?.common?.min, modelXL?.layingBellyUp?.yaw?.common?.max);
        const checkAccXLayingBU = _.inRange(lastestData?.accX, modelXL?.layingBellyUp?.accx?.common?.min, modelXL?.layingBellyUp?.accx?.common?.max);
        const checkAccYLayingBU = _.inRange(lastestData?.accY, modelXL?.layingBellyUp?.accy?.common?.min, modelXL?.layingBellyUp?.accy?.common?.max);
        const checkAccZLayingBU = _.inRange(lastestData?.accZ, modelXL?.layingBellyUp?.accz?.common?.min, modelXL?.layingBellyUp?.accz?.common?.max);
        const checkVelLayingBU = _.inRange(lastestData?.Motion, modelXL?.layingBellyUp?.velocity?.common?.min, modelXL?.layingBellyUp?.velocity?.common?.max);

        const checkPitchLayingDL = _.inRange(lastestData?.Pitch, modelXL?.layingDoughnutLeft?.pitch?.common?.min, modelXL?.layingDoughnutLeft?.pitch?.common?.max);
        const checkRollLayingDL = _.inRange(lastestData?.Roll, modelXL?.layingDoughnutLeft?.roll?.common?.min, modelXL?.layingDoughnutLeft?.roll?.common?.max);
        const checkYawLayingDL = _.inRange(lastestData?.Yaw, modelXL?.layingDoughnutLeft?.yaw?.common?.min, modelXL?.layingDoughnutLeft?.yaw?.common?.max);
        const checkAccXLayingDL = _.inRange(lastestData?.accX, modelXL?.layingDoughnutLeft?.accx?.common?.min, modelXL?.layingDoughnutLeft?.accx?.common?.max);
        const checkAccYLayingDL = _.inRange(lastestData?.accY, modelXL?.layingDoughnutLeft?.accy?.common?.min, modelXL?.layingDoughnutLeft?.accy?.common?.max);
        const checkAccZLayingDL = _.inRange(lastestData?.accZ, modelXL?.layingDoughnutLeft?.accz?.common?.min, modelXL?.layingDoughnutLeft?.accz?.common?.max);
        const checkVelLayingDL = _.inRange(lastestData?.Motion, modelXL?.layingDoughnutLeft?.velocity?.common?.min, modelXL?.layingDoughnutLeft?.velocity?.common?.max);

        const checkPitchLayingDR = _.inRange(lastestData?.Pitch, modelXL?.layingDoughnutRight?.pitch?.common?.min, modelXL?.layingDoughnutRight?.pitch?.common?.max);
        const checkRollLayingDR = _.inRange(lastestData?.Roll, modelXL?.layingDoughnutRight?.roll?.common?.min, modelXL?.layingDoughnutRight?.roll?.common?.max);
        const checkYawLayingDR = _.inRange(lastestData?.Yaw, modelXL?.layingDoughnutRight?.yaw?.common?.min, modelXL?.layingDoughnutRight?.yaw?.common?.max);
        const checkAccXLayingDR = _.inRange(lastestData?.accX, modelXL?.layingDoughnutRight?.accx?.common?.min, modelXL?.layingDoughnutRight?.accx?.common?.max);
        const checkAccYLayingDR = _.inRange(lastestData?.accY, modelXL?.layingDoughnutRight?.accy?.common?.min, modelXL?.layingDoughnutRight?.accy?.common?.max);
        const checkAccZLayingDR = _.inRange(lastestData?.accZ, modelXL?.layingDoughnutRight?.accz?.common?.min, modelXL?.layingDoughnutRight?.accz?.common?.max);
        const checkVelLayingDR = _.inRange(lastestData?.Motion, modelXL?.layingDoughnutRight?.velocity?.common?.min, modelXL?.layingDoughnutRight?.velocity?.common?.max);

        if (checkPitchEating && checkRollEating && checkYawEating && checkAccXEating && checkAccYEating && checkAccZEating && checkVelEating) {
            const labelName = {name: 'Eating'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            console.log('listDataRecord: ', listDataRecord)
            return 'Eating'
        }

        if (checkPitchWalking && checkRollWalking && checkYawWalking && checkAccXWalking && checkAccYWalking && checkAccZWalking && checkVelWalking) {
            const labelName = {name: 'Walking'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Walking'
        }

        if (checkPitchRunning && checkRollRunning && checkYawRunning && checkAccXRunning && checkAccYRunning && checkAccZRunning && checkVelRunning) {
            const labelName = {name: 'Running'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Running'
        }

        if (checkPitchSitting && checkRollSitting && checkYawSitting && checkAccXSitting && checkAccYSitting && checkAccZSitting && checkVelSitting) {
            const labelName = {name: 'Sitting'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Sitting'
        }

        if (checkPitchSN && checkRollSN && checkYawSN && checkAccXSN && checkAccYSN && checkAccZSN && checkVelSN) {
            const labelName = {name: 'Standing Normal'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Standing Normal'
        }

        if (checkPitchSLU && checkRollSLU && checkYawSLU && checkAccXSLU && checkAccYSLU && checkAccZSLU && checkVelSLU) {
            const labelName = {name: 'Standing Looking Up'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Standing Looking Up'
        }

        if (checkPitchLayingSS && checkRollLayingSS && checkYawLayingSS && checkAccXLayingSS && checkAccYLayingSS && checkAccZLayingSS && checkVelLayingSS) {
            const labelName = {name: 'Laying Side Sleeper'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Laying Side Sleeper'
        }

        if (checkPitchLayingSM && checkRollLayingSM && checkYawLayingSM && checkAccXLayingSM && checkAccYLayingSM && checkAccZLayingSM && checkVelLayingSM) {
            const labelName = {name: 'Laying Super Man'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Laying Super Man'
        }

        if (checkPitchLayingBU && checkRollLayingBU && checkYawLayingBU && checkAccXLayingBU && checkAccYLayingBU && checkAccZLayingBU && checkVelLayingBU) {
            const labelName = {name: 'Laying Belly Up'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Laying Belly Up'
        }

        if (checkPitchLayingDL && checkRollLayingDL && checkYawLayingDL && checkAccXLayingDL && checkAccYLayingDL && checkAccZLayingDL && checkVelLayingDL) {
            const labelName = {name: 'Laying Doughnut Left'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Laying Doughnut Left'
        }

        if (checkPitchLayingDR && checkRollLayingDR && checkYawLayingDR && checkAccXLayingDR && checkAccYLayingDR && checkAccZLayingDR && checkVelLayingDR) {
            const labelName = {name: 'Laying Doughnut Right'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Laying Doughnut Right'
        }
    }

    const checkMovementL = () => {
        const checkPitchEating = _.inRange(lastestData?.Pitch, modelL?.eating?.pitch?.common?.min, modelL?.eating?.pitch?.common?.max);
        const checkRollEating = _.inRange(lastestData?.Roll, modelL?.eating?.roll?.common?.min, modelL?.eating?.roll?.common?.max);
        const checkYawEating = _.inRange(lastestData?.Yaw, modelL?.eating?.yaw?.common?.min, modelL?.eating?.yaw?.common?.max);
        const checkAccXEating = _.inRange(lastestData?.accX, modelL?.eating?.accx?.common?.min, modelL?.eating?.accx?.common?.max);
        const checkAccYEating = _.inRange(lastestData?.accY, modelL?.eating?.accy?.common?.min, modelL?.eating?.accy?.common?.max);
        const checkAccZEating = _.inRange(lastestData?.accZ, modelL?.eating?.accz?.common?.min, modelL?.eating?.accz?.common?.max);
        const checkVelEating = _.inRange(lastestData?.Motion, modelL?.eating?.velocity?.common?.min, modelL?.eating?.velocity?.common?.max);
    
        const checkPitchWalking = _.inRange(lastestData?.Pitch, modelL?.walking?.pitch?.common?.min, modelL?.walking?.pitch?.common?.max);
        const checkRollWalking = _.inRange(lastestData?.Roll, modelL?.walking?.roll?.common?.min, modelL?.walking?.roll?.common?.max);
        const checkYawWalking = _.inRange(lastestData?.Yaw, modelL?.walking?.yaw?.common?.min, modelL?.walking?.yaw?.common?.max);
        const checkAccXWalking = _.inRange(lastestData?.accX, modelL?.walking?.accx?.common?.min, modelL?.walking?.accx?.common?.max);
        const checkAccYWalking = _.inRange(lastestData?.accY, modelL?.walking?.accy?.common?.min, modelL?.walking?.accy?.common?.max);
        const checkAccZWalking = _.inRange(lastestData?.accZ, modelL?.walking?.accz?.common?.min, modelL?.walking?.accz?.common?.max);
        const checkVelWalking = _.inRange(lastestData?.Motion, modelL?.walking?.velocity?.common?.min, modelL?.walking?.velocity?.common?.max);
    
        const checkPitchRunning = _.inRange(lastestData?.Pitch, modelL?.running?.pitch?.common?.min, modelL?.running?.pitch?.common?.max);
        const checkRollRunning = _.inRange(lastestData?.Roll, modelL?.running?.roll?.common?.min, modelL?.running?.roll?.common?.max);
        const checkYawRunning = _.inRange(lastestData?.Yaw, modelL?.running?.yaw?.common?.min, modelL?.running?.yaw?.common?.max);
        const checkAccXRunning = _.inRange(lastestData?.accX, modelL?.running?.accx?.common?.min, modelL?.running?.accx?.common?.max);
        const checkAccYRunning = _.inRange(lastestData?.accY, modelL?.running?.accy?.common?.min, modelL?.running?.accy?.common?.max);
        const checkAccZRunning = _.inRange(lastestData?.accZ, modelL?.running?.accz?.common?.min, modelL?.running?.accz?.common?.max);
        const checkVelRunning = _.inRange(lastestData?.Motion, modelL?.running?.velocity?.common?.min, modelL?.running?.velocity?.common?.max);
    
        const checkPitchSitting = _.inRange(lastestData?.Pitch, modelL?.sitting?.pitch?.common?.min, modelL?.sitting?.pitch?.common?.max);
        const checkRollSitting = _.inRange(lastestData?.Roll, modelL?.sitting?.roll?.common?.min, modelL?.sitting?.roll?.common?.max);
        const checkYawSitting = _.inRange(lastestData?.Yaw, modelL?.sitting?.yaw?.common?.min, modelL?.sitting?.yaw?.common?.max);
        const checkAccXSitting = _.inRange(lastestData?.accX, modelL?.sitting?.accx?.common?.min, modelL?.sitting?.accx?.common?.max);
        const checkAccYSitting = _.inRange(lastestData?.accY, modelL?.sitting?.accy?.common?.min, modelL?.sitting?.accy?.common?.max);
        const checkAccZSitting = _.inRange(lastestData?.accZ, modelL?.sitting?.accz?.common?.min, modelL?.sitting?.accz?.common?.max);
        const checkVelSitting = _.inRange(lastestData?.Motion, modelL?.sitting?.velocity?.common?.min, modelL?.sitting?.velocity?.common?.max);
    
        const checkPitchSN = _.inRange(lastestData?.Pitch, modelL?.standingNormal?.pitch?.common?.min, modelL?.standingNormal?.pitch?.common?.max);
        const checkRollSN = _.inRange(lastestData?.Roll, modelL?.standingNormal?.roll?.common?.min, modelL?.standingNormal?.roll?.common?.max);
        const checkYawSN = _.inRange(lastestData?.Yaw, modelL?.standingNormal?.yaw?.common?.min, modelL?.standingNormal?.yaw?.common?.max);
        const checkAccXSN = _.inRange(lastestData?.accX, modelL?.standingNormal?.accx?.common?.min, modelL?.standingNormal?.accx?.common?.max);
        const checkAccYSN = _.inRange(lastestData?.accY, modelL?.standingNormal?.accy?.common?.min, modelL?.standingNormal?.accy?.common?.max);
        const checkAccZSN = _.inRange(lastestData?.accZ, modelL?.standingNormal?.accz?.common?.min, modelL?.standingNormal?.accz?.common?.max);
        const checkVelSN = _.inRange(lastestData?.Motion, modelL?.standingNormal?.velocity?.common?.min, modelL?.standingNormal?.velocity?.common?.max);
    
        const checkPitchSLU = _.inRange(lastestData?.Pitch, modelL?.standingLookingUp?.pitch?.common?.min, modelL?.standingLookingUp?.pitch?.common?.max);
        const checkRollSLU = _.inRange(lastestData?.Roll, modelL?.standingLookingUp?.roll?.common?.min, modelL?.standingLookingUp?.roll?.common?.max);
        const checkYawSLU = _.inRange(lastestData?.Yaw, modelL?.standingLookingUp?.yaw?.common?.min, modelL?.standingLookingUp?.yaw?.common?.max);
        const checkAccXSLU = _.inRange(lastestData?.accX, modelL?.standingLookingUp?.accx?.common?.min, modelL?.standingLookingUp?.accx?.common?.max);
        const checkAccYSLU = _.inRange(lastestData?.accY, modelL?.standingLookingUp?.accy?.common?.min, modelL?.standingLookingUp?.accy?.common?.max);
        const checkAccZSLU = _.inRange(lastestData?.accZ, modelL?.standingLookingUp?.accz?.common?.min, modelL?.standingLookingUp?.accz?.common?.max);
        const checkVelSLU = _.inRange(lastestData?.Motion, modelL?.standingLookingUp?.velocity?.common?.min, modelL?.standingLookingUp?.velocity?.common?.max);
    
        const checkPitchLayingSS = _.inRange(lastestData?.Pitch, modelL?.layingSideSleeper?.pitch?.common?.min, modelL?.layingSideSleeper?.pitch?.common?.max);
        const checkRollLayingSS = _.inRange(lastestData?.Roll, modelL?.layingSideSleeper?.roll?.common?.min, modelL?.layingSideSleeper?.roll?.common?.max);
        const checkYawLayingSS = _.inRange(lastestData?.Yaw, modelL?.layingSideSleeper?.yaw?.common?.min, modelL?.layingSideSleeper?.yaw?.common?.max);
        const checkAccXLayingSS = _.inRange(lastestData?.accX, modelL?.layingSideSleeper?.accx?.common?.min, modelL?.layingSideSleeper?.accx?.common?.max);
        const checkAccYLayingSS = _.inRange(lastestData?.accY, modelL?.layingSideSleeper?.accy?.common?.min, modelL?.layingSideSleeper?.accy?.common?.max);
        const checkAccZLayingSS = _.inRange(lastestData?.accZ, modelL?.layingSideSleeper?.accz?.common?.min, modelL?.layingSideSleeper?.accz?.common?.max);
        const checkVelLayingSS = _.inRange(lastestData?.Motion, modelL?.layingSideSleeper?.velocity?.common?.min, modelL?.layingSideSleeper?.velocity?.common?.max);
    
        const checkPitchLayingSM = _.inRange(lastestData?.Pitch, modelL?.layingSuperMan?.pitch?.common?.min, modelL?.layingSuperMan?.pitch?.common?.max);
        const checkRollLayingSM = _.inRange(lastestData?.Roll, modelL?.layingSuperMan?.roll?.common?.min, modelL?.layingSuperMan?.roll?.common?.max);
        const checkYawLayingSM = _.inRange(lastestData?.Yaw, modelL?.layingSuperMan?.yaw?.common?.min, modelL?.layingSuperMan?.yaw?.common?.max);
        const checkAccXLayingSM = _.inRange(lastestData?.accX, modelL?.layingSuperMan?.accx?.common?.min, modelL?.layingSuperMan?.accx?.common?.max);
        const checkAccYLayingSM = _.inRange(lastestData?.accY, modelL?.layingSuperMan?.accy?.common?.min, modelL?.layingSuperMan?.accy?.common?.max);
        const checkAccZLayingSM = _.inRange(lastestData?.accZ, modelL?.layingSuperMan?.accz?.common?.min, modelL?.layingSuperMan?.accz?.common?.max);
        const checkVelLayingSM = _.inRange(lastestData?.Motion, modelL?.layingSuperMan?.velocity?.common?.min, modelL?.layingSuperMan?.velocity?.common?.max);
    
        const checkPitchLayingBU = _.inRange(lastestData?.Pitch, modelL?.layingBellyUp?.pitch?.common?.min, modelL?.layingBellyUp?.pitch?.common?.max);
        const checkRollLayingBU = _.inRange(lastestData?.Roll, modelL?.layingBellyUp?.roll?.common?.min, modelL?.layingBellyUp?.roll?.common?.max);
        const checkYawLayingBU = _.inRange(lastestData?.Yaw, modelL?.layingBellyUp?.yaw?.common?.min, modelL?.layingBellyUp?.yaw?.common?.max);
        const checkAccXLayingBU = _.inRange(lastestData?.accX, modelL?.layingBellyUp?.accx?.common?.min, modelL?.layingBellyUp?.accx?.common?.max);
        const checkAccYLayingBU = _.inRange(lastestData?.accY, modelL?.layingBellyUp?.accy?.common?.min, modelL?.layingBellyUp?.accy?.common?.max);
        const checkAccZLayingBU = _.inRange(lastestData?.accZ, modelL?.layingBellyUp?.accz?.common?.min, modelL?.layingBellyUp?.accz?.common?.max);
        const checkVelLayingBU = _.inRange(lastestData?.Motion, modelL?.layingBellyUp?.velocity?.common?.min, modelL?.layingBellyUp?.velocity?.common?.max);
    
        const checkPitchLayingDL = _.inRange(lastestData?.Pitch, modelL?.layingDoughnutLeft?.pitch?.common?.min, modelL?.layingDoughnutLeft?.pitch?.common?.max);
        const checkRollLayingDL = _.inRange(lastestData?.Roll, modelL?.layingDoughnutLeft?.roll?.common?.min, modelL?.layingDoughnutLeft?.roll?.common?.max);
        const checkYawLayingDL = _.inRange(lastestData?.Yaw, modelL?.layingDoughnutLeft?.yaw?.common?.min, modelL?.layingDoughnutLeft?.yaw?.common?.max);
        const checkAccXLayingDL = _.inRange(lastestData?.accX, modelL?.layingDoughnutLeft?.accx?.common?.min, modelL?.layingDoughnutLeft?.accx?.common?.max);
        const checkAccYLayingDL = _.inRange(lastestData?.accY, modelL?.layingDoughnutLeft?.accy?.common?.min, modelL?.layingDoughnutLeft?.accy?.common?.max);
        const checkAccZLayingDL = _.inRange(lastestData?.accZ, modelL?.layingDoughnutLeft?.accz?.common?.min, modelL?.layingDoughnutLeft?.accz?.common?.max);
        const checkVelLayingDL = _.inRange(lastestData?.Motion, modelL?.layingDoughnutLeft?.velocity?.common?.min, modelL?.layingDoughnutLeft?.velocity?.common?.max);
    
        const checkPitchLayingDR = _.inRange(lastestData?.Pitch, modelL?.layingDoughnutRight?.pitch?.common?.min, modelL?.layingDoughnutRight?.pitch?.common?.max);
        const checkRollLayingDR = _.inRange(lastestData?.Roll, modelL?.layingDoughnutRight?.roll?.common?.min, modelL?.layingDoughnutRight?.roll?.common?.max);
        const checkYawLayingDR = _.inRange(lastestData?.Yaw, modelL?.layingDoughnutRight?.yaw?.common?.min, modelL?.layingDoughnutRight?.yaw?.common?.max);
        const checkAccXLayingDR = _.inRange(lastestData?.accX, modelL?.layingDoughnutRight?.accx?.common?.min, modelL?.layingDoughnutRight?.accx?.common?.max);
        const checkAccYLayingDR = _.inRange(lastestData?.accY, modelL?.layingDoughnutRight?.accy?.common?.min, modelL?.layingDoughnutRight?.accy?.common?.max);
        const checkAccZLayingDR = _.inRange(lastestData?.accZ, modelL?.layingDoughnutRight?.accz?.common?.min, modelL?.layingDoughnutRight?.accz?.common?.max);
        const checkVelLayingDR = _.inRange(lastestData?.Motion, modelL?.layingDoughnutRight?.velocity?.common?.min, modelL?.layingDoughnutRight?.velocity?.common?.max);
    
        if (checkPitchEating && checkRollEating && checkYawEating && checkAccXEating && checkAccYEating && checkAccZEating && checkVelEating) {
            const labelName = {name: 'Eating'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Eating'
        }

        if (checkPitchWalking && checkRollWalking && checkYawWalking && checkAccXWalking && checkAccYWalking && checkAccZWalking && checkVelWalking) {
            const labelName = {name: 'Walking'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Walking'
        }

        if (checkPitchRunning && checkRollRunning && checkYawRunning && checkAccXRunning && checkAccYRunning && checkAccZRunning && checkVelRunning) {
            const labelName = {name: 'Running'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Running'
        }

        if (checkPitchSitting && checkRollSitting && checkYawSitting && checkAccXSitting && checkAccYSitting && checkAccZSitting && checkVelSitting) {
            const labelName = {name: 'Sitting'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Sitting'
        }

        if (checkPitchSN && checkRollSN && checkYawSN && checkAccXSN && checkAccYSN && checkAccZSN && checkVelSN) {
            const labelName = {name: 'Standing Normal'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Standing Normal'
        }

        if (checkPitchSLU && checkRollSLU && checkYawSLU && checkAccXSLU && checkAccYSLU && checkAccZSLU && checkVelSLU) {
            const labelName = {name: 'Standing Looking Up'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Standing Looking Up'
        }

        if (checkPitchLayingSS && checkRollLayingSS && checkYawLayingSS && checkAccXLayingSS && checkAccYLayingSS && checkAccZLayingSS && checkVelLayingSS) {
            const labelName = {name: 'Laying Side Sleeper'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Laying Side Sleeper'
        }

        if (checkPitchLayingSM && checkRollLayingSM && checkYawLayingSM && checkAccXLayingSM && checkAccYLayingSM && checkAccZLayingSM && checkVelLayingSM) {
            const labelName = {name: 'Laying Super Man'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Laying Super Man'
        }

        if (checkPitchLayingBU && checkRollLayingBU && checkYawLayingBU && checkAccXLayingBU && checkAccYLayingBU && checkAccZLayingBU && checkVelLayingBU) {
            const labelName = {name: 'Laying Belly Up'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Laying Belly Up'
        }

        if (checkPitchLayingDL && checkRollLayingDL && checkYawLayingDL && checkAccXLayingDL && checkAccYLayingDL && checkAccZLayingDL && checkVelLayingDL) {
            const labelName = {name: 'Laying Doughnut Left'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Laying Doughnut Left'
        }

        if (checkPitchLayingDR && checkRollLayingDR && checkYawLayingDR && checkAccXLayingDR && checkAccYLayingDR && checkAccZLayingDR && checkVelLayingDR) {
            const labelName = {name: 'Laying Doughnut Right'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Laying Doughnut Right'
        }
    }

    const checkMovementM = () => {
        const checkPitchEating = _.inRange(lastestData?.Pitch, modelM?.eating?.pitch?.common?.min, modelM?.eating?.pitch?.common?.max);
        const checkRollEating = _.inRange(lastestData?.Roll, modelM?.eating?.roll?.common?.min, modelM?.eating?.roll?.common?.max);
        const checkYawEating = _.inRange(lastestData?.Yaw, modelM?.eating?.yaw?.common?.min, modelM?.eating?.yaw?.common?.max);
        const checkAccXEating = _.inRange(lastestData?.accX, modelM?.eating?.accx?.common?.min, modelM?.eating?.accx?.common?.max);
        const checkAccYEating = _.inRange(lastestData?.accY, modelM?.eating?.accy?.common?.min, modelM?.eating?.accy?.common?.max);
        const checkAccZEating = _.inRange(lastestData?.accZ, modelM?.eating?.accz?.common?.min, modelM?.eating?.accz?.common?.max);
        const checkVelEating = _.inRange(lastestData?.Motion, modelM?.eating?.velocity?.common?.min, modelM?.eating?.velocity?.common?.max);
    
        const checkPitchWalking = _.inRange(lastestData?.Pitch, modelM?.walking?.pitch?.common?.min, modelM?.walking?.pitch?.common?.max);
        const checkRollWalking = _.inRange(lastestData?.Roll, modelM?.walking?.roll?.common?.min, modelM?.walking?.roll?.common?.max);
        const checkYawWalking = _.inRange(lastestData?.Yaw, modelM?.walking?.yaw?.common?.min, modelM?.walking?.yaw?.common?.max);
        const checkAccXWalking = _.inRange(lastestData?.accX, modelM?.walking?.accx?.common?.min, modelM?.walking?.accx?.common?.max);
        const checkAccYWalking = _.inRange(lastestData?.accY, modelM?.walking?.accy?.common?.min, modelM?.walking?.accy?.common?.max);
        const checkAccZWalking = _.inRange(lastestData?.accZ, modelM?.walking?.accz?.common?.min, modelM?.walking?.accz?.common?.max);
        const checkVelWalking = _.inRange(lastestData?.Motion, modelM?.walking?.velocity?.common?.min, modelM?.walking?.velocity?.common?.max);
    
        const checkPitchRunning = _.inRange(lastestData?.Pitch, modelM?.running?.pitch?.common?.min, modelM?.running?.pitch?.common?.max);
        const checkRollRunning = _.inRange(lastestData?.Roll, modelM?.running?.roll?.common?.min, modelM?.running?.roll?.common?.max);
        const checkYawRunning = _.inRange(lastestData?.Yaw, modelM?.running?.yaw?.common?.min, modelM?.running?.yaw?.common?.max);
        const checkAccXRunning = _.inRange(lastestData?.accX, modelM?.running?.accx?.common?.min, modelM?.running?.accx?.common?.max);
        const checkAccYRunning = _.inRange(lastestData?.accY, modelM?.running?.accy?.common?.min, modelM?.running?.accy?.common?.max);
        const checkAccZRunning = _.inRange(lastestData?.accZ, modelM?.running?.accz?.common?.min, modelM?.running?.accz?.common?.max);
        const checkVelRunning = _.inRange(lastestData?.Motion, modelM?.running?.velocity?.common?.min, modelM?.running?.velocity?.common?.max);
    
        const checkPitchSitting = _.inRange(lastestData?.Pitch, modelM?.sitting?.pitch?.common?.min, modelM?.sitting?.pitch?.common?.max);
        const checkRollSitting = _.inRange(lastestData?.Roll, modelM?.sitting?.roll?.common?.min, modelM?.sitting?.roll?.common?.max);
        const checkYawSitting = _.inRange(lastestData?.Yaw, modelM?.sitting?.yaw?.common?.min, modelM?.sitting?.yaw?.common?.max);
        const checkAccXSitting = _.inRange(lastestData?.accX, modelM?.sitting?.accx?.common?.min, modelM?.sitting?.accx?.common?.max);
        const checkAccYSitting = _.inRange(lastestData?.accY, modelM?.sitting?.accy?.common?.min, modelM?.sitting?.accy?.common?.max);
        const checkAccZSitting = _.inRange(lastestData?.accZ, modelM?.sitting?.accz?.common?.min, modelM?.sitting?.accz?.common?.max);
        const checkVelSitting = _.inRange(lastestData?.Motion, modelM?.sitting?.velocity?.common?.min, modelM?.sitting?.velocity?.common?.max);
    
        const checkPitchSN = _.inRange(lastestData?.Pitch, modelM?.standingNormal?.pitch?.common?.min, modelM?.standingNormal?.pitch?.common?.max);
        const checkRollSN = _.inRange(lastestData?.Roll, modelM?.standingNormal?.roll?.common?.min, modelM?.standingNormal?.roll?.common?.max);
        const checkYawSN = _.inRange(lastestData?.Yaw, modelM?.standingNormal?.yaw?.common?.min, modelM?.standingNormal?.yaw?.common?.max);
        const checkAccXSN = _.inRange(lastestData?.accX, modelM?.standingNormal?.accx?.common?.min, modelM?.standingNormal?.accx?.common?.max);
        const checkAccYSN = _.inRange(lastestData?.accY, modelM?.standingNormal?.accy?.common?.min, modelM?.standingNormal?.accy?.common?.max);
        const checkAccZSN = _.inRange(lastestData?.accZ, modelM?.standingNormal?.accz?.common?.min, modelM?.standingNormal?.accz?.common?.max);
        const checkVelSN = _.inRange(lastestData?.Motion, modelM?.standingNormal?.velocity?.common?.min, modelM?.standingNormal?.velocity?.common?.max);
    
        const checkPitchSLU = _.inRange(lastestData?.Pitch, modelM?.standingLookingUp?.pitch?.common?.min, modelM?.standingLookingUp?.pitch?.common?.max);
        const checkRollSLU = _.inRange(lastestData?.Roll, modelM?.standingLookingUp?.roll?.common?.min, modelM?.standingLookingUp?.roll?.common?.max);
        const checkYawSLU = _.inRange(lastestData?.Yaw, modelM?.standingLookingUp?.yaw?.common?.min, modelM?.standingLookingUp?.yaw?.common?.max);
        const checkAccXSLU = _.inRange(lastestData?.accX, modelM?.standingLookingUp?.accx?.common?.min, modelM?.standingLookingUp?.accx?.common?.max);
        const checkAccYSLU = _.inRange(lastestData?.accY, modelM?.standingLookingUp?.accy?.common?.min, modelM?.standingLookingUp?.accy?.common?.max);
        const checkAccZSLU = _.inRange(lastestData?.accZ, modelM?.standingLookingUp?.accz?.common?.min, modelM?.standingLookingUp?.accz?.common?.max);
        const checkVelSLU = _.inRange(lastestData?.Motion, modelM?.standingLookingUp?.velocity?.common?.min, modelM?.standingLookingUp?.velocity?.common?.max);
    
        const checkPitchLayingSS = _.inRange(lastestData?.Pitch, modelM?.layingSideSleeper?.pitch?.common?.min, modelM?.layingSideSleeper?.pitch?.common?.max);
        const checkRollLayingSS = _.inRange(lastestData?.Roll, modelM?.layingSideSleeper?.roll?.common?.min, modelM?.layingSideSleeper?.roll?.common?.max);
        const checkYawLayingSS = _.inRange(lastestData?.Yaw, modelM?.layingSideSleeper?.yaw?.common?.min, modelM?.layingSideSleeper?.yaw?.common?.max);
        const checkAccXLayingSS = _.inRange(lastestData?.accX, modelM?.layingSideSleeper?.accx?.common?.min, modelM?.layingSideSleeper?.accx?.common?.max);
        const checkAccYLayingSS = _.inRange(lastestData?.accY, modelM?.layingSideSleeper?.accy?.common?.min, modelM?.layingSideSleeper?.accy?.common?.max);
        const checkAccZLayingSS = _.inRange(lastestData?.accZ, modelM?.layingSideSleeper?.accz?.common?.min, modelM?.layingSideSleeper?.accz?.common?.max);
        const checkVelLayingSS = _.inRange(lastestData?.Motion, modelM?.layingSideSleeper?.velocity?.common?.min, modelM?.layingSideSleeper?.velocity?.common?.max);
    
        const checkPitchLayingSM = _.inRange(lastestData?.Pitch, modelM?.layingSuperMan?.pitch?.common?.min, modelM?.layingSuperMan?.pitch?.common?.max);
        const checkRollLayingSM = _.inRange(lastestData?.Roll, modelM?.layingSuperMan?.roll?.common?.min, modelM?.layingSuperMan?.roll?.common?.max);
        const checkYawLayingSM = _.inRange(lastestData?.Yaw, modelM?.layingSuperMan?.yaw?.common?.min, modelM?.layingSuperMan?.yaw?.common?.max);
        const checkAccXLayingSM = _.inRange(lastestData?.accX, modelM?.layingSuperMan?.accx?.common?.min, modelM?.layingSuperMan?.accx?.common?.max);
        const checkAccYLayingSM = _.inRange(lastestData?.accY, modelM?.layingSuperMan?.accy?.common?.min, modelM?.layingSuperMan?.accy?.common?.max);
        const checkAccZLayingSM = _.inRange(lastestData?.accZ, modelM?.layingSuperMan?.accz?.common?.min, modelM?.layingSuperMan?.accz?.common?.max);
        const checkVelLayingSM = _.inRange(lastestData?.Motion, modelM?.layingSuperMan?.velocity?.common?.min, modelM?.layingSuperMan?.velocity?.common?.max);
    
        const checkPitchLayingBU = _.inRange(lastestData?.Pitch, modelM?.layingBellyUp?.pitch?.common?.min, modelM?.layingBellyUp?.pitch?.common?.max);
        const checkRollLayingBU = _.inRange(lastestData?.Roll, modelM?.layingBellyUp?.roll?.common?.min, modelM?.layingBellyUp?.roll?.common?.max);
        const checkYawLayingBU = _.inRange(lastestData?.Yaw, modelM?.layingBellyUp?.yaw?.common?.min, modelM?.layingBellyUp?.yaw?.common?.max);
        const checkAccXLayingBU = _.inRange(lastestData?.accX, modelM?.layingBellyUp?.accx?.common?.min, modelM?.layingBellyUp?.accx?.common?.max);
        const checkAccYLayingBU = _.inRange(lastestData?.accY, modelM?.layingBellyUp?.accy?.common?.min, modelM?.layingBellyUp?.accy?.common?.max);
        const checkAccZLayingBU = _.inRange(lastestData?.accZ, modelM?.layingBellyUp?.accz?.common?.min, modelM?.layingBellyUp?.accz?.common?.max);
        const checkVelLayingBU = _.inRange(lastestData?.Motion, modelM?.layingBellyUp?.velocity?.common?.min, modelM?.layingBellyUp?.velocity?.common?.max);
    
        const checkPitchLayingDL = _.inRange(lastestData?.Pitch, modelM?.layingDoughnutLeft?.pitch?.common?.min, modelM?.layingDoughnutLeft?.pitch?.common?.max);
        const checkRollLayingDL = _.inRange(lastestData?.Roll, modelM?.layingDoughnutLeft?.roll?.common?.min, modelM?.layingDoughnutLeft?.roll?.common?.max);
        const checkYawLayingDL = _.inRange(lastestData?.Yaw, modelM?.layingDoughnutLeft?.yaw?.common?.min, modelM?.layingDoughnutLeft?.yaw?.common?.max);
        const checkAccXLayingDL = _.inRange(lastestData?.accX, modelM?.layingDoughnutLeft?.accx?.common?.min, modelM?.layingDoughnutLeft?.accx?.common?.max);
        const checkAccYLayingDL = _.inRange(lastestData?.accY, modelM?.layingDoughnutLeft?.accy?.common?.min, modelM?.layingDoughnutLeft?.accy?.common?.max);
        const checkAccZLayingDL = _.inRange(lastestData?.accZ, modelM?.layingDoughnutLeft?.accz?.common?.min, modelM?.layingDoughnutLeft?.accz?.common?.max);
        const checkVelLayingDL = _.inRange(lastestData?.Motion, modelM?.layingDoughnutLeft?.velocity?.common?.min, modelM?.layingDoughnutLeft?.velocity?.common?.max);
    
        const checkPitchLayingDR = _.inRange(lastestData?.Pitch, modelM?.layingDoughnutRight?.pitch?.common?.min, modelM?.layingDoughnutRight?.pitch?.common?.max);
        const checkRollLayingDR = _.inRange(lastestData?.Roll, modelM?.layingDoughnutRight?.roll?.common?.min, modelM?.layingDoughnutRight?.roll?.common?.max);
        const checkYawLayingDR = _.inRange(lastestData?.Yaw, modelM?.layingDoughnutRight?.yaw?.common?.min, modelM?.layingDoughnutRight?.yaw?.common?.max);
        const checkAccXLayingDR = _.inRange(lastestData?.accX, modelM?.layingDoughnutRight?.accx?.common?.min, modelM?.layingDoughnutRight?.accx?.common?.max);
        const checkAccYLayingDR = _.inRange(lastestData?.accY, modelM?.layingDoughnutRight?.accy?.common?.min, modelM?.layingDoughnutRight?.accy?.common?.max);
        const checkAccZLayingDR = _.inRange(lastestData?.accZ, modelM?.layingDoughnutRight?.accz?.common?.min, modelM?.layingDoughnutRight?.accz?.common?.max);
        const checkVelLayingDR = _.inRange(lastestData?.Motion, modelM?.layingDoughnutRight?.velocity?.common?.min, modelM?.layingDoughnutRight?.velocity?.common?.max);
    
        if (checkPitchEating && checkRollEating && checkYawEating && checkAccXEating && checkAccYEating && checkAccZEating && checkVelEating) {
            const labelName = {name: 'Eating'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Eating'
        }

        if (checkPitchWalking && checkRollWalking && checkYawWalking && checkAccXWalking && checkAccYWalking && checkAccZWalking && checkVelWalking) {
            const labelName = {name: 'Walking'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Walking'
        }

        if (checkPitchRunning && checkRollRunning && checkYawRunning && checkAccXRunning && checkAccYRunning && checkAccZRunning && checkVelRunning) {
            const labelName = {name: 'Running'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Running'
        }

        if (checkPitchSitting && checkRollSitting && checkYawSitting && checkAccXSitting && checkAccYSitting && checkAccZSitting && checkVelSitting) {
            const labelName = {name: 'Sitting'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Sitting'
        }

        if (checkPitchSN && checkRollSN && checkYawSN && checkAccXSN && checkAccYSN && checkAccZSN && checkVelSN) {
            const labelName = {name: 'Standing Normal'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Standing Normal'
        }

        if (checkPitchSLU && checkRollSLU && checkYawSLU && checkAccXSLU && checkAccYSLU && checkAccZSLU && checkVelSLU) {
            const labelName = {name: 'Standing Looking Up'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Standing Looking Up'
        }

        if (checkPitchLayingSS && checkRollLayingSS && checkYawLayingSS && checkAccXLayingSS && checkAccYLayingSS && checkAccZLayingSS && checkVelLayingSS) {
            const labelName = {name: 'Laying Side Sleeper'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Laying Side Sleeper'
        }

        if (checkPitchLayingSM && checkRollLayingSM && checkYawLayingSM && checkAccXLayingSM && checkAccYLayingSM && checkAccZLayingSM && checkVelLayingSM) {
            const labelName = {name: 'Laying Super Man'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Laying Super Man'
        }

        if (checkPitchLayingBU && checkRollLayingBU && checkYawLayingBU && checkAccXLayingBU && checkAccYLayingBU && checkAccZLayingBU && checkVelLayingBU) {
            const labelName = {name: 'Laying Belly Up'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Laying Belly Up'
        }

        if (checkPitchLayingDL && checkRollLayingDL && checkYawLayingDL && checkAccXLayingDL && checkAccYLayingDL && checkAccZLayingDL && checkVelLayingDL) {
            const labelName = {name: 'Laying Doughnut Left'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Laying Doughnut Left'
        }

        if (checkPitchLayingDR && checkRollLayingDR && checkYawLayingDR && checkAccXLayingDR && checkAccYLayingDR && checkAccZLayingDR && checkVelLayingDR) {
            const labelName = {name: 'Laying Doughnut Right'}
            const objectConcat = Object.assign(labelName, lastestData)
            console.log('objectConcat:', objectConcat)
            listDataRecord.push(objectConcat)
            return 'Laying Doughnut Right'
        }
    }

    const callMQTT = () => {
        if (monitorId) {
            const host = "ws://broker.emqx.io:8083/mqtt";
            const clientId = "mqttjs_" + Math.random().toString(16).substr(2, 8);

            const options = {
                keepalive: 30,
                clientId: clientId,
                protocolId: "MQTT",
                protocolVersion: 4,
                clean: true,
                reconnectPeriod: 1000,
                connectTimeout: 30 * 1000,
                will: {
                    topic: "WillMsg",
                    payload: "Connection Closed abnormally..!",
                    qos: 0,
                    retain: false,
                },
                rejectUnauthorized: false,
            };
            console.log("connecting mqtt client");
            const client = mqtt.connect(host, options);
            client.on("error", (err) => {
                console.log("Connection error: ", err);
                client.end();
            });

            client.on("reconnect", () => {
                console.log("Reconnecting...");
            });

            client.on("connect", () => {
                console.log("Client connected:" + clientId);
                if (monitorId.length === 8) {
                    client.subscribe("/mypetgo/" + monitorId, { qos: 0 });
                    client.subscribe("/mypetgo/init/" + monitorId, { qos: 1 });
                }
            });
    
            client.on("message", (topic, message, packet) => {
                var obj = JSON.parse(message.toString());
                let labelTime = { time: moment().format()}
                let lastObject = Object.assign(obj, labelTime)
                console.log('lastObject: ', lastObject)
                if (!obj?.ID) {
                    setLastestData(obj)
                    listData.push(obj)
                }
            })
        }
    }

    useEffect(() => {
        callMQTT()
    }, [monitorId])

    const renderLine1RealTime = () => {
        return (
            <div>
                <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">Pitch</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">Roll</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">Yaw</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">Acc. X</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">Acc. Y</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">Acc. Z</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnTitleParam">Velocity</div>
                    </div>
                </div>
            </div>
        )
    };

    const renderLastestParam = () => {
        return (
            <div>
                <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.Pitch}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.Roll}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.Yaw}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.accX}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.accY}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.accY}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="ctnLastestParam">{lastestData?.Motion}</div>
                    </div>
                </div>
            </div>
        )
    }

    const Button = ({ text, className, pin, textColor, onClick }) => {
        return (
            <div onClick={onClick} className={className}>
                {pin && <img src={ic_pin} style={{ height: 24, width: 24 }} />}
                <p style={{ fontSize: 16, fontWeight: 700, color: textColor }}>{text}</p>
            </div>
        )
    }

    const renderTextInput = (placeholder, className, value, setValue) => {
        return (
            <div>
                <input onChange={(txt) => setValue(txt?.target?.value)} value={value} className={className} placeholder={placeholder} />
            </div>
        )
    }

    const renderBottom = () => {
        return (
            <div className="ctnBottom">
                <div className="ctnLogo">
                    <img src={mypetgo_logo} style={{ height: 24, width: 110, marginRight: 18 }} />
                    <Button text={lastestData?.Battery ? (lastestData?.Battery + '%') : ''} className="classPin" pin={true} />
                </div>
                <div className="ctnLogo">
                    {renderTextInput('Breed', 'textInputBreed', breedValue, setBreedValue)}
                    {renderTextInput('Age(yrs)', 'textInputAge', ageValue, setAgeValue)}
                    {renderTextInput('Weight (kg)', 'textInputWeight', weightValue, setWeightValue)}
                    {renderTextInput('Gender', 'textInputAge', genderValue, setGenderValue)}
                    {renderTextInput('Size', 'textInputAge', sizeValue, setSizeValue)}
                    <Button text='Save' className='buttonSave' textColor='white' onClick={_onClickSave} />
                    <Button text='JSON' className='buttonSave' textColor='white' onClick={_onClickJson} />
                </div>
            </div>
        )
    }

    const _onClickSave = () => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id')
        setMonitorId(id)
        const time = performance.now()
        setTimeStart(time)
    }

    const _onClickJson = () => {
        const label = [
          {
            breed: breedValue,
            age: ageValue,
            weight: weightValue,
            gender: genderValue,
            size: sizeValue,
          },
        ];
        const dataConcat = _.concat(label, listDataRecord)
        const labelTime = [{ time: moment().format() }]
        const newData = dataConcat
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(newData)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = `${moment().format('YYYY-MM-DD_H-mm-ss')}-${sizeValue}.json`;
        link.click();
    }

    const checkSizeMovement = () => {
        if (_.includes(['M', 'S', 'XS'], sizeValue)) {
            return checkMovementM()
        } else if (sizeValue === 'L') {
            return checkMovementL()
        } else {
            return checkMovementXl()
        }
    }

    const renderMovement = () => {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30}}>
                <div style={{fontSize: 23, fontWeight: 'bold'}}>
                    POSITION:
                </div>
                <div style={{fontSize: 60, fontWeight: 'bold', marginTop: 20}}>
                    {checkSizeMovement()}
                </div>
            </div>
        )
    }

    return (
        <div className="container">
            <div>
                {renderLine1RealTime()}
                {lastestData && renderLastestParam()}
            </div>
            <div>
                {renderMovement()}
            </div>
            <div>
                {renderBottom()}
            </div>
        </div>
    )
}
